import { DateTime } from 'luxon';
import { AcumenDocument, AcumenDocumentDB, AcumenDocuments, AcumenDocumentTable } from './../../shared/models';
import { ShowImageModalComponent } from './../../shared/components/show-image-modal/show-image-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from './../../shared/services/firebase.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AcumenFirebaseService } from 'src/app/shared/services/acumen-firebase.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-acumen-document-uploads',
  templateUrl: './acumen-document-uploads.component.html',
  styleUrls: ['./acumen-document-uploads.component.scss'],
})
export class AcumenDocumentUploadsComponent implements OnInit {
  tableHeaders = ['No', 'User_Email', 'Type', 'isActive', 'Date_/_Time'];
  documentsTableData: AcumenDocumentTable[];
  paginationPageSize = 8;
  userRole: string;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private acumenFirebaseService: AcumenFirebaseService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }
    this.getAttachments();
  }

  async getAttachments(): Promise<void> {
    this.spinnerService.show();
    this.acumenFirebaseService.getDocumentData('acumen-documents').subscribe((data: AcumenDocumentDB[]) => {
      this.spinnerService.hide();
      this.documentsTableData = [];
      let index = 0;

      // Parent Object
      Object.keys(data).forEach(async (i) => {
        const parentElem: AcumenDocuments[] = data[i];
        // Document Type Object
        Object.keys(parentElem).forEach(async (j) => {
          const element = data[i][j]; // chargeslip, facesheet, other

          // Data Object
          Object.keys(element).forEach(async (k) => {
            const obj: AcumenDocument = { ...data[i][j][k] };

            Object.keys(obj).forEach(async (l) => {
              const dataObj: AcumenDocument = data[i][j][k][l];
              const userSnapshot = await this.acumenFirebaseService.getDataSync(
                `acumen-users/${Object.keys(data[i])[0]}/`
              );
              const user = userSnapshot.val();
              const obj: AcumenDocumentTable = {
                No: ++index,
                Key: Object.keys(data[i][j][k])[0],
                Type: dataObj.documentType,
                User_Email: user.email,
                UserKey: Object.keys(data[i])[0],
                isActive: dataObj.isActive,
                FilePath: dataObj.documentPath,
                'Date_/_Time': DateTime.fromMillis(+dataObj.timestamp)
                  .setZone('America/Los_Angeles')
                  .toLocaleString(DateTime.DATETIME_SHORT),
              };
              this.documentsTableData.push(obj);
            });
          });
        });
      });
    });
  }

  deleteDocument = (document: AcumenDocumentTable): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.acumenFirebaseService.deleteDocument(document.UserKey, document.Type, document.Key, document.FilePath);
      }
    });
  };

  downloadDocument = (selectedDocument: AcumenDocumentTable): void => {
    this.acumenFirebaseService.getFileUrl(selectedDocument.FilePath).then((url: string) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        var blob = new Blob([xhr.response], { type: 'image/png' });
        var url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =
          `${selectedDocument.No} - ${selectedDocument.UserKey} | ${selectedDocument['Date_/_Time']}.png` || 'download';
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
      };
      xhr.open('GET', url);
      xhr.send();
    });
  };

  playDocument = (document: AcumenDocumentTable): void => {
    this.acumenFirebaseService.getFileUrl(document.FilePath).then((path) => {
      const modalRef = this.modalService.open(ShowImageModalComponent, {
        animation: true,
        centered: true,
        windowClass: 'imageModal',
      });
      modalRef.componentInstance.image = path;
      modalRef.result
        .then((result) => {})
        .catch((err) => {
          console.error(err);
        });
    });
  };
}
