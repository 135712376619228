import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from 'src/app/shared/services/branding.service';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
})
export class BrandingComponent implements OnInit {
  userRole: string;
  user: any;
  PrimaryColor: string;
  SecondaryColor: string;
  logoFile: any;
  logo_url: any;

  constructor(
    private brandingService: BrandingService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.getBranding();
  }

  getBranding() {
    this.spinnerService.show();
    this.brandingService
      .getBranding()
      .then((res) => {
        this.PrimaryColor = res.data.primary_color;
        this.SecondaryColor = res.data.secondary_color;
        this.logo_url = res.data.logo_url || null;
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  onLogoChange(event: any) {
    if (!event.target.files[0]) return;
    this.logoFile = event.target.files[0];
  }

  setBranding() {
    this.spinnerService.show();
    this.brandingService
      .setBranding(this.logoFile, this.PrimaryColor, this.SecondaryColor)
      .then((res) => {
        this.PrimaryColor = res.data.primary_color;
        this.SecondaryColor = res.data.secondary_color;
        this.logo_url = res.data.logo_url;
        this.getBranding();
        this.toastr.success('Change successful');
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }
}
