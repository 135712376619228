<div class="page">
  <div class="page-title__bar">
    <div>
      <h2 class="title">Acumen Document Uploads</h2>
    </div>
  </div>

  <div class="right__panel calendar-data__wrapper">
    <div class="table-data__wrapper table-responsive calendar-data--table">
      <app-table-pagination-client
        [headers]="tableHeaders"
        [data]="documentsTableData"
        [hasActions]="true"
        [deleteFunction]="deleteDocument"
        [downloadFunction]="downloadDocument"
        [playContent]="playDocument"
        [pageSize]="paginationPageSize"
        [currentPage]="1"
      >
      </app-table-pagination-client>
    </div>
  </div>
</div>
