import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(private http: HttpClient) {}

  async getPatientDetails(patient_id: string | number): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));

    return await this.http
      .get<any>(`${environment.PATIENT}/${patient_id}`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${user.stsTokenManager.accessToken}`),
      })
      .toPromise();
  }

  async updateUserDetails(patient_id: string, data: any): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));

    return await this.http
      .put<any>(`${environment.PATIENT}/${patient_id}`, data, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${user.stsTokenManager.accessToken}`),
      })
      .toPromise();
  }
}
