<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ "Contact History" }}</h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    <div class="row form-group">
      <div class="col-md-2"><strong>Appointment Date:</strong></div>
      <div class="col-md-4 pr-0">{{reminderData.appt_date ? reminderData.appt_date : "Not Specified"}}</div>
      <div class="col-md-2"><strong>Appointment Time:</strong></div>
      <div class="col-md-4 pr-0">{{reminderData.appt_time ? reminderData.appt_time : "Not Specified"}}</div>
    </div>

    <div class="row form-group">
      <div class="col-md-2"><strong>Confirmed:</strong></div>
      <div class="col-md-4 pl-0">{{reminderData.is_confirmed ? "Yes" : "No"}}</div>
      <div class="col-md-2"><strong>Cell Number:</strong></div>
      <div class="col-md-4 pr-0">{{reminderData.cellNumber ? reminderData.cellNumber : "Not Specified"}}</div>
    </div>
    <div class="row form-group">
      <div class="col-md-2"><strong>SMS Sent At:</strong></div>
      <div class="col-md-4 pl-0">{{reminderData.dateTimeSmsSent ? reminderData.dateTimeSmsSent : 'Not Specified'}}</div>
      <div class="col-md-2"><strong>Call sent At:</strong></div>
      <div class="col-md-4 pl-0">
        {{reminderData.dateTimeCallSent ? reminderData.dateTimeCallSent : 'Not Specified'}}
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-6">
        <div><strong>SMS Result(s)</strong></div>
        <div class="col-md-4 pl-0" *ngIf="reminderData.smsResult.length === 0">{{'No Sms Initiated'}}</div>
        <div class="pl-0" *ngIf="reminderData.smsResult.length > 0">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Content</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of reminderData.smsResult; let i = index">
                <th scope="row">{{i + 1}}</th>
                <td>{{record.status | titlecase}}</td>
                <td *ngIf="record.date_sent">{{record.date_sent | date: 'MM/dd/yyyy hh:mm a' }}</td>
                <td *ngIf="!record.date_sent">Not Specified</td>
                <td>{{record.body}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-6">
        <div><strong>Call Result(s)</strong></div>
        <div class="col-md-4 pl-0" *ngIf="reminderData.callResults.length === 0">{{'No Calls Initiated'}}</div>
        <div class="pl-0">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Duration (seconds)</th>
                <th scope="col">Answered By</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of reminderData.callResults; let i = index">
                <th scope="row">{{i + 1}}</th>
                <td *ngIf="record.status">{{record.status | titlecase}}</td>
                <td *ngIf="!record.status">Not Specified</td>
                <td *ngIf="record.date_called">{{record.date_called | date: 'dd/MM/yyyy hh:mm a' }}</td>
                <td *ngIf="!record.date_called">Not Specified</td>
                <td>{{record.duration ? record.duration : "Not Specified"}}</td>
                <td>{{checkAnsweredBy(record.answered_by)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>
