import { Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  username: string;
  user: any;
  userRole: string;
  enableToggle: boolean;
  showNotification: boolean;
  @Output() showHideToggle = new EventEmitter<boolean>();

  constructor(private authService: AuthService) {
    this.enableToggle = false;
    this.showNotification = false;
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.userRole = await this.authService.getUserRole();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.username = this.user?.email.split('@')[0];
  }

  logout(): void {
    this.authService.logout();
  }

  toggleMenu() {
    this.enableToggle = !this.enableToggle;
    this.showHideToggle.emit(this.enableToggle);
  }

  toggleNotification() {
    this.showNotification = !this.showNotification;
  }
}
