<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Form Rules: <strong>{{ title }}</strong>
  </h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="createFlag" [@fadeInOut]>
    <fieldset class="form-group mt-3 mb-4 p-3">
      <legend class="w-auto px-1">Rule Creation</legend>
      <div class="d-flex">
        <input
          class="form-control mr-3"
          [(ngModel)]="rule_title"
          (keyup.enter)="createRule()"
          placeholder="Enter the new Rule"
        />

        <select class="form-control" [(ngModel)]="provider">
          <option value="" disabled hidden selected>Select a Provider</option>
          <option *ngFor="let provider of providerData" [value]="provider.provider_id">
            {{ provider.description }}
          </option>
        </select>
        <select class="form-control ml-1" [(ngModel)]="rule_type">
          <option value="" disabled hidden selected>Select Rule Type</option>
          <option value="order">Order</option>
          <option value="appointment">Appointment</option>
        </select>
      </div>
      <button class="btn btn-primary btn-fr mt-3 action-btn" (click)="createRule()">
        Save<i class="fa fa-floppy-o ml-2" aria-hidden="true"></i>
      </button>
      <button class="btn btn-danger btn-fr mr-3 mt-3" (click)="cancelSwitch()">
        Cancel<i class="fa fa-times ml-2" aria-hidden="true"></i>
      </button>
    </fieldset>
  </div>

  <div class="page-title__bar">
    <h5 class="title">Assgined Rules</h5>

    <div class="user-actions" *ngIf="!createFlag" [@fadeInOut]>
      <button class="btn action-btn" (click)="createSwitch()">
        Create Rule<i class="fa fa-plus fa-lg ml-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div
    class="table-data__wrapper table-responsive calendar-data--table"
    *ngIf="formRulesTableData.length; else emptyList"
  >
    <app-table-pagination-client
      [headers]="tableHeaders"
      [data]="formRulesTableData"
      [pageSize]="4"
      [currentPage]="1"
      [hasActions]="true"
      [deleteFunction]="deleteRule"
      [paginateId]="2"
    >
    </app-table-pagination-client>
  </div>
  <ng-template #emptyList>
    <div class="d-flex justify-content-center">
      <h6>
        <img src="../../../../assets/no-result-search.png" class="emptyPic" />
        <p>No Rules have been declared yet.</p>
      </h6>
    </div>
  </ng-template>
</div>
