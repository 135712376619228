import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Assistant } from 'src/app/shared/models';
import { GET_ALL_SMS_SCRIPTS_RESP, SMSScriptsData, SMSScriptsTableData } from 'src/app/shared/models/smsScripts';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { SMSService } from 'src/app/shared/services/sms.service';
import { DateTime } from 'luxon';
import { SmsScriptsModalComponent } from 'src/app/shared/components/sms-scripts-modal/sms-scripts-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sms-scripts',
  templateUrl: './sms-scripts.component.html',
  styleUrls: ['./sms-scripts.component.scss'],
})
export class SmsScriptsComponent implements OnInit {
  userRole: string;
  assistantInfo: Assistant = null;
  scriptsData: SMSScriptsData[];
  scriptsTableHeaders = ['id', 'script', 'event_type', 'type', 'created_at'];
  existingPatientsTableData: SMSScriptsTableData[] = [];
  newPatientsTableData: SMSScriptsTableData[] = [];
  currentPageOfNewScripts: number = 1;
  currentPageOfExistingScripts: number = 1;
  totalPagesOfNewScripts: number = 0;
  totalPagesOfExistingScripts: number = 0;
  noDataForExistingPatients = false;
  noDataForNewPatients = false;

  constructor(
    private authService: AuthService,
    private smsService: SMSService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private firebaseService: FirebaseService,
    private modalService: NgbModal
  ) {}

  async ngOnInit(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }

    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getAllSMSScripts();
  }

  getAllSMSScripts() {
    this.spinnerService.show();
    this.smsService
      .getAllSMSScripts()
      .then((resp: GET_ALL_SMS_SCRIPTS_RESP) => {
        let index1 = 1,
          index2 = 1;

        resp.data.map((script) => {
          const script_data = {
            script_id: script.id,
            script: script.script,
            event_type: script.event_type,
            type:
              (script.type === 'A' && 'Appointment SMS') ||
              (script.type === 'V' && 'Appointment Call') ||
              (script.type === 'O' && 'Order SMS') ||
              (script.type === 'R' && 'Autoresponder Script') ||
              (script.type === 'C' && 'Confirmation Script') ||
              '',
            is_new_patient_script: script.is_new_patient_script,
            created_at: DateTime.fromISO(script.created_at).toLocaleString(DateTime.DATE_SHORT),
          };

          if (script.is_new_patient_script) {
            this.newPatientsTableData.push({ id: index1++, ...script_data });
          } else this.existingPatientsTableData.push({ id: index2++, ...script_data });
        });

        if (this.existingPatientsTableData.length === 0) {
          this.noDataForExistingPatients = true;
        }

        if (this.newPatientsTableData.length === 0) {
          this.noDataForNewPatients = true;
        }
      })
      .catch((err) => {
        this.toastr.error(err);
        this.noDataForExistingPatients = true;
        this.noDataForNewPatients = true;
      })
      .finally(() => this.spinnerService.hide());
  }

  setTotalPagesForNewScripts(pages): void {
    this.totalPagesOfNewScripts = pages;
  }

  setTotalPagesForExistingScripts(pages): void {
    this.totalPagesOfExistingScripts = pages;
  }

  addScript = (is_new_patient_script: boolean): void => {
    const smsScriptModalRef = this.modalService.open(SmsScriptsModalComponent, { centered: true, size: 'xl' });
    smsScriptModalRef.componentInstance.title = 'Script Creation';
    smsScriptModalRef.componentInstance.type = 'create';

    smsScriptModalRef.componentInstance.scriptData = {
      id: 1,
      script: '',
      event_type: '',
      type: 'A',
      is_new_patient_script: is_new_patient_script,
    };

    smsScriptModalRef.result
      .then((resp: SMSScriptsTableData) => {
        if (resp) {
          const script_data = { script_id: resp.id, ...resp };
          script_data.id = 1;
          script_data.type =
            script_data.type === 'A'
              ? 'Appointment SMS'
              : script_data.type === 'V'
              ? 'Appointment Call'
              : script_data.type === 'R'
              ? 'Autoresponder Script'
              : script_data.type === 'C'
              ? 'Confirmation Script'
              : 'Order SMS';
          script_data.created_at = DateTime.fromISO(script_data.created_at).toLocaleString(DateTime.DATE_SHORT);

          if (script_data.is_new_patient_script) {
            if (this.noDataForNewPatients) this.noDataForNewPatients = false;
            this.newPatientsTableData.unshift(script_data);
            this.fixDataOrder(this.newPatientsTableData);
          } else {
            if (this.noDataForExistingPatients) this.noDataForExistingPatients = false;
            this.existingPatientsTableData.unshift(script_data);
            this.fixDataOrder(this.existingPatientsTableData);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  editScript = (record: SMSScriptsTableData): void => {
    const smsScriptModalRef = this.modalService.open(SmsScriptsModalComponent, { centered: true, size: 'xl' });
    smsScriptModalRef.componentInstance.title = 'Script Updation';
    smsScriptModalRef.componentInstance.type = 'update';
    smsScriptModalRef.componentInstance.scriptData = {
      id: record.id,
      script_id: record.script_id,
      script: record.script,
      event_type: record.event_type,
      type:
        record.type === 'Appointment SMS'
          ? 'A'
          : record.type === 'Appointment Call'
          ? 'V'
          : record.type === 'Order SMS'
          ? 'O'
          : record.type === 'Autoresponder Script'
          ? 'R'
          : record.type === 'Confirmation Script'
          ? 'C'
          : '',
      is_new_patient_script: record.is_new_patient_script,
      created_at: record.created_at,
    };

    smsScriptModalRef.result
      .then((resp: SMSScriptsTableData) => {
        if (resp) {
          let index;

          resp.type =
            resp.type === 'A'
              ? 'Appointment SMS'
              : resp.type === 'V'
              ? 'Appointment Call'
              : resp.type === 'R'
              ? 'Autoresponder Script'
              : resp.type === 'C'
              ? 'Confirmation Script'
              : 'Order SMS';

          if (resp.is_new_patient_script) {
            index = this.newPatientsTableData.findIndex((script) => script.script_id === resp.script_id);
            this.newPatientsTableData[index] = resp;
          } else {
            index = this.existingPatientsTableData.findIndex((script) => script.script_id === resp.script_id);
            this.existingPatientsTableData[index] = resp;
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deleteScript = (record: SMSScriptsTableData): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.smsService
          .deleteSMSScript(record.script_id)
          .then(() => {
            this.spinnerService.hide();
            if (record.is_new_patient_script) {
              this.newPatientsTableData = this.newPatientsTableData.filter(
                (script) => script.script_id != record.script_id
              );
              this.fixDataOrder(this.newPatientsTableData);
            } else {
              this.existingPatientsTableData = this.existingPatientsTableData.filter(
                (script) => script.script_id != record.script_id
              );
              this.fixDataOrder(this.existingPatientsTableData);
            }

            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The Script was deleted Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Script deletion process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  fixDataOrder(array: SMSScriptsTableData[]) {
    let index = 0;

    for (const elem of array) {
      elem.id = ++index;
    }
  }
}
