<div class="page">
  <div class="page-title__bar">
    <h2 class="title">Order Payments</h2>

    <div class="action__panel">
      <button
        class="download__panel"
        (click)="exportPayments('orders')"
        ngbTooltip="Export As CSV"
        [openDelay]="400"
        [closeDelay]="400"
      >
        <i class="fa fa-cloud-download" style="font-size: large" aria-hidden="true"></i>
      </button>
      <button class="add-user-btn" (click)="openDateFilter('paidPayments')">Filter By Date</button>
      <div class="search__panel btn-demo">
        <select ngbTooltip="Search Criteria" [(ngModel)]="searchCriteria">
          <option value="patient_name">Patient Name</option>
          <option value="order_summary">Order Summary</option>
        </select>
        <div class="input-group">
          <input
            #searchBar
            style="height: 38px"
            type="search"
            (keyup.enter)="searchRecords($event, 'paymentsReports')"
            [(ngModel)]="searchKeyword"
            (search)="searchRecords($event, 'paymentsReports', true)"
            class="form-control change"
            placeholder="Search"
          />
          <div class="input-group-append" (click)="searchRecords($event, 'paymentsReports')">
            <span class="input-group-text">
              <img src="../../../assets/icons/daily-screening.svg" alt="search" />
            </span>
          </div>
        </div>
        <div
          [ngClass]="userRole != 'practice_admin' ? 'archived__panel btn-demo mr-4' : 'archived__panel btn-demo'"
        ></div>
      </div>
      <div class="d-flex align-items-center">
        <p class="mb-1 mr-2 ml-2">Non Archived</p>
        <ui-switch
          size="small"
          color="#073786"
          #attachmentSwitch
          (change)="onChangeSwitch('paymentReports')"
        ></ui-switch>
        <p class="mb-1 mr-2 ml-2">Archived</p>
      </div>
    </div>
  </div>

  <div>
    <app-table-pagination-client
      [headers]="paymentTableHeaders"
      [data]="paymentsTableData"
      [pageSize]="pageSize"
      [hasActions]="true"
      [archivedFunction]="archivedFunction"
      [currentPage]="currentPage"
      [paginationFirstPage]="firstPage"
      [paginationPrevPage]="prevPage"
      [paginationNextPage]="nextPage"
      [paginationLastPage]="lastPage"
      [paginationCustomPage]="customPage"
      [serverSidePagination]="tablePages"
      [orderByFunction]="orderBy"
    >
    </app-table-pagination-client>
  </div>

  <div class="page-title__bar mt-2">
    <h2 class="title">Payment Statements</h2>

    <section class="action__panel">
      <button
        class="download__panel"
        (click)="exportPayments('statements')"
        ngbTooltip="Export As CSV"
        [openDelay]="400"
        [closeDelay]="400"
      >
        <i class="fa fa-cloud-download" style="font-size: large" aria-hidden="true"></i>
      </button>
      <button class="add-user-btn" (click)="openDateFilter('allPayments')">Filter By Date</button>

      <div class="search__panel btn-demo">
        <select ngbTooltip="Search Criteria" [(ngModel)]="searchCriteriaForPaidPayments">
          <option value="patient_name">Patient Name</option>
        </select>

        <div class="input-group">
          <input
            #searchBarForPaidPayments
            style="height: 38px"
            type="search"
            (keyup.enter)="searchRecords($event, 'paidPayments')"
            [(ngModel)]="searchKeywordForPaidPayments"
            (search)="searchRecords($event, 'paidPayments', true)"
            class="form-control change"
            placeholder="Search"
          />
          <div class="input-group-append" (click)="searchRecords($event, 'paidPayments')">
            <span class="input-group-text">
              <img src="../../../assets/icons/daily-screening.svg" alt="search" />
            </span>
          </div>
        </div>
        <div
          [ngClass]="userRole != 'practice_admin' ? 'archived__panel btn-demo mr-4' : 'archived__panel btn-demo'"
        ></div>
      </div>
      <div class="d-flex align-items-center">
        <p class="mb-1 mr-2 ml-2">Auto Payments</p>
        <ui-switch
          size="small"
          color="#073786"
          #attachmentSwitchForAutoPayments
          (change)="onChangeSwitch('paidPayments')"
        ></ui-switch>
      </div>
      <div class="d-flex align-items-center">
        <p class="mb-1 mr-2 ml-2">Non Archived</p>
        <ui-switch
          size="small"
          color="#073786"
          #attachmentSwitchForPaidPayments
          (change)="onChangeSwitch('paidPayments')"
        ></ui-switch>
        <p class="mb-1 mr-2 ml-2">Archived</p>
      </div>
    </section>
  </div>

  <div>
    <app-table-pagination-client
      [headers]="paymentTableHeadersForPaidPayments"
      [data]="paidPaymentsTableData"
      [pageSize]="pageSizePaidPayments"
      [hasActions]="true"
      [archivedFunction]="archivedFunctionForPaidPayments"
      [currentPage]="currentPagePaidPayments"
      [paginationFirstPage]="firstPagePaidPayments"
      [paginationPrevPage]="prevPagePaidPayments"
      [paginationNextPage]="nextPagePaidPayments"
      [paginationLastPage]="lastPagePaidPayments"
      [paginationCustomPage]="customPagePaidPayments"
      [serverSidePagination]="tablePagesOfPaidPayments"
      [orderByFunction]="orderByPaidPayments"
    >
    </app-table-pagination-client>
  </div>
</div>
