import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DbConfigBackend } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogsService {
  constructor(private http: HttpClient) {}

  getActivityLog(practice_id: string, dbConfig: DbConfigBackend): any {
    return this.http.post(`${environment.GET_ACTIVITY_LOG_API}/`, {
      practice_id,
      dbConfig,
    });
  }
}
