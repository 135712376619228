import {
  GET_PROVIDER_RESP,
  DbConfigBackend,
  ADD_PROVIDER_RESP,
} from './../models/';
import { AppService } from './app.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient, private appService: AppService) {}

  validateLoginInfo(credentials: {
    username: string;
    password: string;
    hashedPassword?: string;
  }): Promise<any> {
    return this.http
      .post(`${environment.ADMIN_LOGIN}`, credentials)
      .toPromise();
  }

  testDatabaseConnection(dbConfig): any {
    return this.http
      .post(`${environment.TEST_DATABASE_CONNECTION}`, dbConfig)
      .toPromise();
  }

  createOrUpdatePractice(data): any {
    return this.http.post(environment.CREATE_UPDATE_PRACTICE, data).toPromise();
  }

  createStatusForPractice(data): any {
    return this.http
      .post(environment.CREATE_STATUS_FOR_PRACTICE, data)
      .toPromise();
  }

  async getPracticeColorLabels(reqBody: { practice_id: string }): Promise<any> {
    return await this.http
      .post(environment.GET_PRACTICE_COLOR_LABELS, reqBody)
      .toPromise();
  }

  getProviders(
    practice_id: string,
    dbConfig: DbConfigBackend
  ): Observable<GET_PROVIDER_RESP> {
    return this.http.post<GET_PROVIDER_RESP>(environment.GET_PROVIDERS, {
      practice_id,
      dbConfig,
    });
  }

  deleteProvider(
    practice_id: string,
    provider_id: string,
    dbConfig: DbConfigBackend
  ): Promise<any> {
    return this.http
      .post(environment.DELETE_PROVIDER, { provider_id, practice_id, dbConfig })
      .toPromise();
  }

  addProvider(
    provider_name: string,
    practice_id: string,
    dbConfig: DbConfigBackend,
    provider_id?: string
  ): Promise<ADD_PROVIDER_RESP> {
    return this.http
      .post<ADD_PROVIDER_RESP>(environment.ADD_PROVIDER, {
        provider_name,
        practice_id,
        dbConfig,
        provider_id,
      })
      .toPromise();
  }

  editProvider(
    provider_id: string,
    provider_name: string,
    practice_id: string,
    dbConfig: DbConfigBackend
  ): Promise<ADD_PROVIDER_RESP> {
    return this.http
      .post<ADD_PROVIDER_RESP>(environment.EDIT_PROVIDER, {
        provider_name,
        practice_id,
        dbConfig,
        provider_id
      })
      .toPromise();
  }
}
