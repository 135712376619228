<div class="modal-header">
  <h4 class="modal-title">Add Benefit Insurance</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <fieldset class="form-inline justify-content-end">
    <div class="form-group justify-content-end">
      <label for="typeahead-http">CPT Code:</label>
      <input
        id="typeahead-http"
        ng-maxlength="5"
        class="form-control mx-sm-3"
        [class.is-invalid]="searchFailed"
        [ngModel]="model"
        [ngbTypeahead]="search"
        placeholder="Enter CPT Code"
        (ngModelChange)="onTextChange($event)"
      />
      <small *ngIf="searching" class="form-text text-muted">Searching...</small>
      <div class="invalid-feedback d-flex justify-content-end" *ngIf="searchFailed">
        Sorry, suggestions could not be loaded.
      </div>
    </div>

    <ng-container *ngIf="benefitInsurance">
      <div class="form-group mt-2">
        <label for="contract">Contract:</label>
        <input id="contract" type="text" disabled class="form-control mx-sm-3" [ngModel]="benefitInsurance.contract" />
      </div>

      <div class="form-group mt-2">
        <label for="mppr">MPPR:</label>
        <input id="mppr" type="text" disabled class="form-control mx-sm-3" [ngModel]="benefitInsurance.mppr" />
      </div>

      <div class="form-group mt-2">
        <label for="allowable">Allowable:</label>
        <input
          id="allowable"
          type="text"
          disabled
          class="form-control mx-sm-3"
          [ngModel]="benefitInsurance.allowable"
        />
      </div>
      <div class="form-group mt-2">
        <label for="contract_description">Contract Description:</label>
        <input
          id="contract_description"
          type="text"
          disabled
          class="form-control mx-sm-3"
          [ngModel]="benefitInsurance.contract_description"
        />
      </div>
    </ng-container>
  </fieldset>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="createBenefitInsurance()">Create</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
