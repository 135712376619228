import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { FirebaseApp } from '@angular/fire';
import { list } from 'rxfire/database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AcumenFirebaseService {
  firebaseApp: FirebaseApp = null;

  constructor(
    private appService: AppService,
    private spinnerService: NgxSpinnerService
  ) {
    this.firebaseApp = firebase.initializeApp(
      environment.acumenFirebaseConfig,
      'acumenFirebaseApp'
    );
  }

  getFirebaseApp(): FirebaseApp {
    if (this.firebaseApp) return this.firebaseApp;

    this.firebaseApp = firebase.initializeApp(
      environment.acumenFirebaseConfig,
      'acumenFirebaseApp'
    );
    return this.firebaseApp;
  }

  getData(ref: string): Promise<any> {
    return this.getFirebaseApp().database().ref(ref).once('value');
  }

  getFileUrl(filePath: string): any {
    return this.getFirebaseApp().storage().ref(filePath).getDownloadURL();
  }

  async deleteAudioDictation(
    userId: string,
    dbKey: string,
    filePath: string
  ): Promise<any> {
    // Deleting Database Record
    return new Promise((resolve, reject) => {
      this.spinnerService.show();
      this.getFirebaseApp()
        .database()
        .ref()
        .child(`/acumen-dictations/${userId}/${dbKey}`)
        .remove()
        .then((res) => {
          // Deleting Video Dictation file in storage
          this.getFirebaseApp().storage().ref(filePath).delete();

          this.spinnerService.hide();
          this.appService.showToast('Audio Dictation Deleted', 'success');
          resolve('Success');
        })
        .catch((err) => {
          this.spinnerService.hide();
          this.appService.showToast("Dictation couldn't be deleted", 'error');
          reject(err);
        });
    });
  }

  async getDataSync(ref: string): Promise<any> {
    return await this.getFirebaseApp().database().ref(ref).once('value');
  }

  getDocumentData(ref: string) {
    const tableRef = this.getFirebaseApp().database().ref(ref);
    return list(tableRef).pipe(
      map((changes) =>
        changes.map((c) => {
          const temp = {};
          temp[c.snapshot.key] = c.snapshot.val();
          return temp;
        })
      )
    );
  }

  async deleteDocument(
    userId: string,
    documentType: string,
    dbKey: string,
    filePath: string
  ): Promise<any> {
    // Deleting Database Record
    return new Promise((resolve, reject) => {
      this.spinnerService.show();
      this.getFirebaseApp()
        .database()
        .ref()
        .child(`/acumen-documents/${userId}/${documentType}/${dbKey}`)
        .remove()
        .then((res) => {
          // Deleting Video Dictation file in storage
          this.getFirebaseApp().storage().ref(filePath).delete();

          this.spinnerService.hide();
          this.appService.showToast('Document Deleted', 'success');
          resolve('Success');
        })
        .catch((err) => {
          this.spinnerService.hide();
          this.appService.showToast("Document couldn't be deleted", 'error');
          reject(err);
        });
    });
  }
}
