import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Talk from 'talkjs';

@Injectable({
  providedIn: 'root',
})
export class TalkService {
  user = JSON.parse(localStorage.getItem('user'));
  constructor(private http: HttpClient) {}

  async createUser(applicationUser: any) {
    await Talk.ready;
    return new Talk.User({
      id: applicationUser.id,
      name: applicationUser.username,
      photoUrl: applicationUser.photoUrl,
      role: applicationUser.role,
      custom: applicationUser.custom,
    });
  }

  private static APP_ID = environment.TALKJS_APP_ID;
  private currentTalkUser: Talk.User;

  async createCurrentSession(practice, practiceName) {
    await Talk.ready;
    const user = {
      id: practice,
      username: practiceName,
      role: 'assistant',
    };
    this.currentTalkUser = await this.createUser(user);
    const session = new Talk.Session({
      appId: TalkService.APP_ID,
      me: this.currentTalkUser,
    });
    return session;
  }

  async createInbox(session: Talk.Session) {
    return session.createInbox({ showFeedHeader: false, messageField: { spellcheck: true } });
  }

  async createOrderInbox(session: Talk.Session, patient) {
    const conversation = await this.getOrCreateConversation(session, patient);
    const chatbox = session.createChatbox(conversation);
    chatbox.select(conversation);
    return chatbox;
  }

  async updateConversation(conversationId: string, data: any): Promise<any> {
    return await this.http
      .put<any>(`${environment.BASE_URL}/web/conversation/${conversationId}`, data, {
        headers: { Authorization: `Bearer ${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async ConnectMrnWithGuest(conversationId: string, MRN: any): Promise<any> {
    return await this.http
      .post<any>(`${environment.BASE_URL}/web/conversation/${conversationId}/assign/mrn/${MRN}`, null, {
        headers: { Authorization: `Bearer ${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async assignConversation(conversationId: string, data: any): Promise<any> {
    return await this.http
      .put<any>(`${environment.BASE_URL}/web/conversation/${conversationId}/assign`, data, {
        headers: { Authorization: `Bearer ${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async archiveTalkjsConversation(conversationId: string, data: any): Promise<any> {
    return await this.http
      .put<any>(`${environment.BASE_URL}/web/conversation/${conversationId}/archive`, data, {
        headers: { Authorization: `Bearer ${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  downloadTalkjsConversation(conversationId: string): Observable<Blob> {
    return this.http.get(`${environment.BASE_URL}/web/conversation/${conversationId}/download`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.user.stsTokenManager.accessToken}`),
      responseType: 'blob',
    });
  }

  exportCSV(startDate, endDate): Observable<Blob> {
    return this.http.post(
      `${environment.BASE_URL}/web/practice/conversation-details/csv`,
      { startDate, endDate },
      {
        headers: { Authorization: `Bearer ${this.user.stsTokenManager.accessToken}` },
        responseType: 'blob',
      }
    );
  }

  async getConversationDetails(): Promise<any> {
    return await this.http
      .get<any>(`${environment.BASE_URL}/web/conversation`, {
        headers: { Authorization: `Bearer ${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async getUsers(): Promise<any> {
    return await this.http
      .get<any>(`${environment.BASE_URL}/practice/users`, {
        headers: { Authorization: `Bearer ${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async getOrCreateConversation(session: Talk.Session, otherApplicationUser: any) {
    const otherUser = await this.createUser(otherApplicationUser);
    const conversation = session.getOrCreateConversation(Talk.oneOnOneId(this.currentTalkUser, otherUser));
    conversation.setParticipant(this.currentTalkUser);
    conversation.setParticipant(otherUser);
    return conversation;
  }
}
