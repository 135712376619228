<div class="page">
  <div class="page-title__bar">
    <h2 class="title">Benefit Configurations</h2>
  </div>
  <div class="tab-content" id="myTabContent" style="padding: 20px">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <!-- Nav Link 1 -->
      <li [ngbNavItem]="1">
        <a ngbNavLink>Manage Payers</a>
        <ng-template ngbNavContent>
          <div class="user-actions">
            <button *ngIf="this.isPayerSelected" class="btn add-user-btn" (click)="addMpd()">
              Add MPD
            </button>
          </div>
          <app-table-pagination-client *ngIf="this.isPayerSelected" [headers]="mpdTableHeaders" [data]="payerMPD"
            [pageSize]="5" [currentPage]="1" [paginateId]="2" [hasActions]="true" [deleteFunction]="deleteFunction"
            [editFunction]="selectMPD">
          </app-table-pagination-client>
        </ng-template>
      </li>
      <!-- Nav Link 2 -->
      <li [ngbNavItem]="2">
        <a ngbNavLink>Contracts</a>
        <ng-template ngbNavContent>
          <div>
            <div class="user-actions">
              <button *ngIf="this.isPayerSelected" class="btn add-user-btn" (click)="uploadAttachment()">
                Upload File (CSV)
              </button>
            </div>
            <app-table-pagination-client *ngIf="this.isPayerSelected" [headers]="contractRatesTableHeaders"
              [data]="payerContracts" [pageSize]="10" [currentPage]="1" [paginateId]="1"
              [serverSidePagination]="tablePages" [paginationFirstPage]="firstPage" [paginationPrevPage]="prevPage"
              [paginationNextPage]="nextPage" [paginationLastPage]="lastPage" [paginationCustomPage]="customPage">
            </app-table-pagination-client>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
    <div class="right__panel calendar-data__wrapper">
      <div class="table-data__wrapper table-responsive calendar-data--table">
        <app-table-pagination-client [headers]="payerTableHeaders" [currentPage]="1" [data]="payersDataTable"
          [hasActions]="true" [paginateId]="3" [editFunction]="selectPayer" [pageSize]="pageSize">
        </app-table-pagination-client>
      </div>
    </div>
  </div>
</div>