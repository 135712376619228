<div class="modal-content">
  <div class="modal-header">
    <h2>Send Message</h2>
    <span class="close" (click)="closeModal()">&times;</span>
  </div>
  <div class="input-group">
    <label for="messageType">Select Type:</label>
    <select id="messageType" name="messageType" [(ngModel)]="messageType" (change)="onMessageTypeChange()">
      <option value="" disabled selected>Select Type</option>
      <option value="User Message">PWA Message</option>
      <option value="Text Message">Text Message</option>
    </select>
  </div>
  <div class="input-group">
    <label for="phoneNumber">Phone Number:</label>
    <input type="text" [(ngModel)]="phoneNumber" (input)="formatPhoneNumber($event)" placeholder="Enter phone number" maxlength="14" />
  </div>
  <div class="input-group">
    <label for="message">Message:</label>
    <textarea id="message" name="message" [(ngModel)]="message" placeholder="Enter your message"></textarea>
  </div>
  <button class="btn-send" (click)="sendMessage()">Send</button>
</div>
