import { Component, OnInit } from '@angular/core';
import { AppService } from './../../shared/services/app.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { PayerService } from 'src/app/shared/services/payer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UiSwitchComponent } from 'ngx-toggle-switch';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

import { AuthService } from './../../shared/services/auth.service';
import {
  GET_PROVIDERS_LIST_RESP,
  ServerSidePages,
  GET_PAYERS_CLIENT,
  GET_PAYER_CONTRACTS,
  GET_PAYER_MPD,
  GET_PAYERS,
  GET_MPD,
  SELECT_MPD,
  Assistant,
} from 'src/app/shared/models';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payer-benefit',
  templateUrl: './payer-benefit.component.html',
  styleUrls: ['./payer-benefit.component.scss'],
})
export class PayerBenefitComponent implements OnInit {
  searchBenefit: string = 'payer';
  searchPatient: any;
  assistantInfo: Assistant = null;
  payerTableHeaders: string[] = ['description', 'financial_class'];
  contractRatesTableHeaders: string[] = ['description', 'cpt_code', 'facility_rate', 'non_facility_rate'];
  mpdTableHeaders: string[] = ['mpd', 'proc_order'];
  orderTableHeaders: string[] = [
    'patient_name',
    'provider_name',
    'order_summary',
    'date',
    'order_facility',
    'physician_notes',
  ];
  assistantPractice: string;
  practiceProviders: GET_PROVIDERS_LIST_RESP[];
  tablePages: ServerSidePages;
  pageSize: number = 10;
  attachmentSwitch: UiSwitchComponent;
  assistantUID: string;
  payer: any = [];
  selectedPayer: GET_PAYERS;
  selectedMPD: GET_MPD;
  isPayerSelected: boolean = false;
  payerContracts: any = [];
  payerMPD: any = [];
  payersDataTable: any = [];
  selectedPayerID: any;
  userRole: string;

  constructor(
    private appService: AppService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private payerService: PayerService,
    private firebaseService: FirebaseService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    if (!!!loggedInUser) this.authService.logout();

    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }
    this.spinnerService.show();
    this.assistantUID = loggedInUser.uid;
    this.assistantPractice = loggedInUser.displayName;
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);

    this.getPayers();
  }

  onChangePayer(payer_id): void {
    this.isPayerSelected = true;
    this.spinnerService.show();
    this.payerService.getPayerContracts(payer_id).then((response: GET_PAYER_CONTRACTS) => {
      this.spinnerService.hide();
      if (response.code === 200) {
        this.payerContracts = response.data.data;
        this.setPagination(response);
      }
    });
    this.payerService.getPayerMPD(payer_id).then((res: GET_PAYER_MPD) => {
      if (res.code === 200) {
        this.payerMPD = res.data.data;
      }
    });
  }

  getChangePayerData(page: number = 1, limit: number = this.pageSize, payer_id): void {
    this.spinnerService.show();
    this.payerService.getPayerContracts(payer_id, page, limit).then((response: GET_PAYER_CONTRACTS) => {
      this.spinnerService.hide();
      if (response.code === 200) {
        this.payerContracts = response.data.data;
        this.setPagination(response);
      }
    });
  }

  setPagination(response) {
    this.tablePages = {
      currentPage: response.data.currentPage,
      totalPages: Math.trunc((response.data.total + response.data.limit - 1) / response.data.limit),
      lastPage: Math.trunc((response.data.total + response.data.limit - 1) / response.data.limit),
      nextPage: response.data.nextPage,
      prevPage: response.data.previousPage,
      firstPage: 1,
      total: response.data.total,
    };
  }

  lastPage = (): void => {
    if (this.tablePages.nextPage)
      this.getChangePayerData(this.tablePages.lastPage, this.pageSize, this.selectedPayerID);
  };

  nextPage = (): void => {
    if (this.tablePages.nextPage)
      this.getChangePayerData(this.tablePages.nextPage, this.pageSize, this.selectedPayerID);
  };

  prevPage = (): void => {
    if (this.tablePages.prevPage)
      this.getChangePayerData(this.tablePages.prevPage, this.pageSize, this.selectedPayerID);
  };

  firstPage = (): void => {
    if (this.tablePages.prevPage)
      this.getChangePayerData(this.tablePages.firstPage, this.pageSize, this.selectedPayerID);
  };

  customPage = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.tablePages.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (typeof pageNumber === 'number' && pageNumber <= this.tablePages.totalPages && 0 < pageNumber) {
      this.getChangePayerData(pageNumber, this.pageSize, this.selectedPayerID);
    } else
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.tablePages.firstPage +
          ' to ' +
          this.tablePages.lastPage
      );
  };

  getPayers(): void {
    this.spinnerService.show();
    this.payerService.getPayerByClientId(this.assistantInfo.client_id).then((resp: GET_PAYERS_CLIENT) => {
      this.spinnerService.hide();
      if (resp.code === 200) {
        this.payer = resp.data || [];
        this.payersDataTable = this.payer.map((payers: GET_PAYERS) => ({
          id: payers.payer_id,
          description: payers.description,
          financial_class: payers.financial_class,
        }));
      } else {
        console.log('Unable to get payers data');
      }
    });
  }

  uploadAttachment(): void {
    let fileChooserAcceptType = '.csv';
    const fileChooser = document.createElement('input');
    fileChooser.type = 'file';
    fileChooser.accept = fileChooserAcceptType;
    fileChooser.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      if (file) {
        this.spinnerService.show();
        this.payerService.uploadCSV(file, this.selectedPayerID).subscribe(
          (resp) => {
            this.spinnerService.hide();
            if (resp.code === 200) {
              this.onChangePayer(this.selectedPayerID);
              this.selectPayer(this.selectedPayer);
              this.toastr.success('File Uploaded!');
            }
          },
          (error) => {
            this.spinnerService.hide();
            this.toastr.error(error);
          }
        );
      }
    });
    fileChooser.click();
  }

  selectPayer = (payer): void => {
    if (!this.selectedPayer || payer?.id !== this.selectedPayer?.payer_id) {
      this.selectedPayer = { id: payer.id, ...payer };
      this.selectedPayerID = payer.id;
      if (payer.id) {
        this.onChangePayer(payer.id);
        this.appService.getProviders(this.assistantPractice).then((resp: GET_PROVIDERS_LIST_RESP[]) => {
          this.practiceProviders = resp;
        });
      }
    }
  };

  selectMPD = (mpd): void => {
    const self = this;
    mpd.id = mpd.mpd_id;
    if (!this.selectedMPD || mpd.mpd_id !== this.selectedMPD?.mpd_id) {
      this.selectedMPD = { id: mpd.id, ...mpd };
      if (mpd.id) {
        Swal.fire({
          titleText: 'Update MPD',
          html:
            '<h5>MPD</h5> <input value="' +
            mpd.mpd +
            '"' +
            'type="number" autocomplete="off" class="swalInput" id="mpd">' +
            '<h5>Proc Order</h5> <input value="' +
            mpd.proc_order +
            '"' +
            ' type="number" autocomplete="off" class="swalInput" id="order">',
          showConfirmButton: true,
          confirmButtonText: 'Save',
          confirmButtonColor: '#073786',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          preConfirm: function () {
            const mpd_val = $('input[id="mpd"]').val();
            const proc_order = $('input[id="order"]').val();
            let data = { mpd: mpd_val, proc_order: proc_order };
            if (mpd_val === null || proc_order === null) return;
            self.payerService
              .updateMPD(self.selectedPayerID, mpd.mpd_id, data)
              .then((resp) => {
                if (resp.code !== 200) return self.toastr.error('Resquest failed');
                if (resp.code === 200) {
                  self.onChangePayer(self.selectedPayerID);
                  return self.toastr.success('Request Completed.');
                }
              })
              .catch(() => {
                Swal.showValidationMessage(`Request failed.`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          }
        });
        console.log('Here');
      }
    }
  };

  deleteFunction = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#073786',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.payerService
          .deleteMPD(this.selectedPayerID, data.mpd_id)
          .then((resp) => {
            this.spinnerService.hide();
            if (resp.code === 200) {
              this.onChangePayer(this.selectedPayerID);
              this.toastr.success('Payer was deleted successfully');
            } else {
              this.toastr.error("Could't complete operation due to error");
            }
          })
          .catch((err) => {
            this.spinnerService.hide();
            this.toastr.error("Could't complete operation due to error");
          });
      }
    });
  };

  addMpd = (): void => {
    const self = this;
    Swal.fire({
      titleText: 'Add MPD',
      html:
        '<h5>MPD</h5> <input type="number" autocomplete="off" class="swalInput" id="mpd">' +
        '<h5>Proc Order</h5> <input type="number" autocomplete="off" class="swalInput" id="order">',
      showConfirmButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#073786',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      preConfirm: function () {
        const mpd = $('input[id="mpd"]').val();
        const proc_order = $('input[id="order"]').val();

        if (mpd === null || proc_order === null) return;
        self.payerService
          .addMPD(self.selectedPayerID, mpd, proc_order)
          .then((resp) => {
            if (resp.code !== 200) return self.toastr.error('Resquest failed');
            if (resp.code === 200) {
              self.onChangePayer(self.selectedPayerID);
              return self.toastr.success('Request Completed.');
            }
          })
          .catch(() => {
            Swal.showValidationMessage(`Request failed.`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  };
}
