import { UnlockCode } from './../../shared/models/unlockCode';
import { UnlockCodesService } from './../../shared/services/unlock-codes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirebaseService } from './../../shared/services/firebase.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { Assistant, UnlockCodeFirebase } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unlock-codes',
  templateUrl: './unlock-codes.component.html',
  styleUrls: ['./unlock-codes.component.scss'],
})
export class UnlockCodesComponent implements OnInit {
  tableHeaders = ['Id', 'Title', 'Unlock_Code'];
  unlockCodes: UnlockCode[] = [];
  assistantInfo: Assistant = null;
  numberItems = 4;
  totalUnlockCodes: number = 1; // we are using this as index, so we initialize this with 1.
  isExistingUnlockCodesChecked: boolean = false;
  userRole: string;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private unlockCodesService: UnlockCodesService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }

    this.spinnerService.show();
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getUnlockCodes();
  }

  getUnlockCodes(): void {
    this.spinnerService.show();
    this.unlockCodesService.getUnlockCodes(this.assistantInfo.practiceId).subscribe((data: UnlockCodeFirebase[]) => {
      this.spinnerService.hide();
      this.unlockCodes = Object.keys(data).map((unlockCode, index) => {
        return {
          Key: data[unlockCode].key,
          Id: index + 1,
          Title: data[unlockCode].title,
          Unlock_Code: data[unlockCode].unlockCode,
        };
      });
    });
  }

  addUnlockCode(): void {
    Swal.fire({
      titleText: 'Add Unlock Code',
      html:
        '<h5>Title </h5> <input type="text" autocomplete="off" class="swalInput" id="unlockCodeTitle">' +
        '<h5>Unlock Code </h5> <input type="text" autocomplete="off" class="swalInput" id="unlockCode">',
      showConfirmButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#073786',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      preConfirm: function () {
        return new Promise((resolve, reject) => {
          resolve({
            title: $('input[id="unlockCodeTitle"]').val(),
            unlockCode: $('input[id="unlockCode"]').val(),
          });
        });
      },
    }).then((data: any) => {
      if (!!data?.value?.title && !!data?.value?.unlockCode) {
        const objToUpload = {
          title: data.value.title,
          unlockCode: data.value.unlockCode,
        };

        this.unlockCodesService.uploadUnlockCode(this.assistantInfo.practiceId, objToUpload);
      }
    });
  }

  deleteUnlockCode = (record: UnlockCode): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.unlockCodesService.deleteUnlockCode(record, this.assistantInfo).then((res) => {
          Swal.fire('Deleted!', 'Unlock Code has been deleted.', 'success');
        });
      }
    });
  };
}
