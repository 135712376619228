import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Assistant,
  formResponseResp,
  formResponseTableData,
  GET_FORMS_RESPONSES_DETAILS_RESP,
  GET_FORMS_RESPONSES_RESP,
  ServerSidePages,
} from '../../models';
import { FirebaseService } from '../../services/firebase.service';
import { FormsService } from '../../services/forms.service';
import { ToastrService } from 'ngx-toastr';
import { PreviewFormModalComponent } from '../preview-form-modal/preview-form-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTime } from 'luxon';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import Swal from 'sweetalert2';
import { UiSwitchComponent } from 'ngx-toggle-switch';

@Component({
  selector: 'app-form-response-modal',
  templateUrl: './form-response-modal.component.html',
  styleUrls: ['./form-response-modal.component.scss'],
})
export class FormResponseModalComponent implements OnInit {
  tableHeaders = [
    { title: 'id', value: 'id', sort: false, orderBy: '' },
    {
      title: 'patient_name',
      value: 'patient_name',
      sort: true,
      orderBy: '',
    },
    { title: 'task_description', value: 'task_description', sort: false, orderBy: '' },
    { title: 'completed_at', value: 'completed_at', sort: true, orderBy: '' },
    { title: 'created_at', value: 'created_at', sort: true, orderBy: '' },
  ];
  assistantInfo: Assistant = null;
  @Input() title: string;
  @Input() form_id: string;
  filterByDate: any = {};
  startDate: string;
  endDate: string;
  formResponseTableData: formResponseTableData[];
  pageSize: number = 4;
  tablePages: ServerSidePages;
  @ViewChild('attachmentSwitch', { static: false })
  attachmentSwitch: UiSwitchComponent;
  searchKeyword: string = '';
  searchCriteria: string = 'patient_name';

  @HostListener('document:keydown.shift.n')
  createGroup() {
    this.openDateModal();
  }

  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private firebaseService: FirebaseService,
    private modalService: NgbModal,
    private formService: FormsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getFormResponses();
  }

  getFormResponses(page: number = 1, limit: number = this.pageSize, filterByDate: any = {}, orderBy: any = {}): void {
    this.spinnerService.show();

    if (filterByDate?.startDate && filterByDate?.endDate) {
      filterByDate.startDate = this.formatDate(filterByDate.startDate);
      filterByDate.endDate = this.formatDate(filterByDate.endDate);
    }

    this.formService
      .getFormResponses(
        this.form_id,
        this.assistantInfo.client_id,
        page,
        limit,
        orderBy,
        this.searchKeyword,
        this.searchCriteria,
        filterByDate,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null
      )
      .then((resp: GET_FORMS_RESPONSES_RESP) => {
        this.tablePages = {
          currentPage: resp.data.currentPage,
          totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };
        this.formResponseTableData = resp.data.data.map((form: formResponseResp, index: number) => ({
          id: ++index,
          task_id: form.id,
          patient_name: form.patient_name,
          task_description: form?.task_description,
          completed_at: form.completed_at ? DateTime.fromISO(form.completed_at).toLocaleString(DateTime.DATE_SHORT) : null,
          created_at: DateTime.fromISO(form.created_at).toLocaleString(DateTime.DATE_SHORT),
        }));
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
        console.log(err);
      })
      .finally(() => this.spinnerService.hide());
  }

  formatDate(date) {
    const formatted_date = new Date(date.year, date.month - 1, date.day + 1);
    return formatted_date;
  }

  lastPage = (): void => {
    let order,
      date_range = {};
    this.tableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.startDate && this.endDate) {
      date_range = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    }

    if (this.tablePages.nextPage) this.getFormResponses(this.tablePages.lastPage, this.pageSize, date_range, order);
  };

  nextPage = (): void => {
    let order,
      date_range = {};
    this.tableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.startDate && this.endDate) {
      date_range = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    }

    if (this.tablePages.nextPage) this.getFormResponses(this.tablePages.nextPage, this.pageSize, date_range, order);
  };

  prevPage = (): void => {
    let order,
      date_range = {};
    this.tableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.startDate && this.endDate) {
      date_range = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    }

    if (this.tablePages.prevPage) this.getFormResponses(this.tablePages.prevPage, this.pageSize, date_range, order);
  };

  firstPage = (): void => {
    let order,
      date_range = {};
    this.tableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.startDate && this.endDate) {
      date_range = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    }

    if (this.tablePages.prevPage) this.getFormResponses(this.tablePages.firstPage, this.pageSize, date_range, order);
  };

  orderBy = (column_name: string): void => {
    const index = this.tableHeaders.findIndex((header) => header.value === column_name);
    let date_range = {};

    this.tableHeaders.forEach((header) => {
      if (header.value != column_name) header.orderBy = '';
    });

    if (this.tableHeaders[index].orderBy === 'ASC') this.tableHeaders[index].orderBy = 'DESC';
    else this.tableHeaders[index].orderBy = 'ASC';

    const order = {
      column_name,
      sort: this.tableHeaders[index].orderBy,
    };

    if (this.startDate && this.endDate) {
      date_range = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    }

    this.getFormResponses(this.tablePages.currentPage, this.pageSize, date_range, order);
  };

  searchRecords(event: KeyboardEvent, clearSearch?: boolean): void {
    if (clearSearch && event.code === 'Enter') this.searchKeyword = '';

    let order,
      date_range = {};
    this.tableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.startDate && this.endDate) {
      date_range = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    }

    this.getFormResponses(1, this.pageSize, date_range, order);
  }

  openDateModal(): void {
    const filterModal = this.modalService.open(FilterModalComponent, {
      animation: true,
      centered: true,
    });

    filterModal.componentInstance.header = 'Date//Completed At';
    filterModal.componentInstance.datePicker = true;

    if (this.filterByDate?.startDate && this.filterByDate?.endDate) {
      filterModal.componentInstance.fromDate = this.startDate;
      filterModal.componentInstance.toDate = this.endDate;
    }

    filterModal.result
      .then((result) => {
        if (result) {
          this.tableHeaders.forEach((header) => {
            header.orderBy = '';
          });
          this.filterByDate.startDate = result.startDate;
          this.filterByDate.endDate = result.endDate;
          this.startDate = result.startDate;
          this.endDate = result.endDate;

          this.getFormResponses(this.tablePages.firstPage, this.pageSize, this.filterByDate);
        } else {
          this.tableHeaders.forEach((header) => {
            header.orderBy = '';
          });
          this.filterByDate = {};
          this.startDate = '';
          this.endDate = '';
          this.getFormResponses(this.tablePages.firstPage);
        }
      })
      .catch((err) => {
        if (err != 0) console.error(err);
      });
  }

  downloadFormResponse = (record: formResponseTableData): void => {
    this.spinnerService.show();
    this.formService.downloadFormResponse(this.form_id, this.assistantInfo.client_id, record.task_id).subscribe(
      (data) => {
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${record.task_description || this.title}.pdf`;
        link.click();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again');
      }
    );
  };

  renderFormResponse = (record: formResponseTableData): void => {
    this.spinnerService.show();
    this.formService
      .getFormResponseDetails(this.form_id, this.assistantInfo.client_id, record.task_id)
      .then((resp: GET_FORMS_RESPONSES_DETAILS_RESP) => {
        const renderRef = this.modalService.open(PreviewFormModalComponent, { centered: true });
        renderRef.componentInstance.renderResponse = resp.data.clientForm;
        renderRef.componentInstance.patientData = resp.data.patient;
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  };

  onChangeSwitch(page = 1) {
    this.getFormResponses(page);
  }

  archivedFunction = (record: formResponseTableData): void => {
    Swal.fire({
      title: 'Archive/Unarchive',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        const data = {
          is_archived: this.attachmentSwitch.checked ? false : true,
        };
        this.formService
          .updateFormResponse(record.task_id, data)
          .then(() => {
            this.spinnerService.hide();
            this.getFormResponses(this.tablePages.currentPage);
            this.toastr.success(
              this.attachmentSwitch.checked ? 'The dictation was unarchived.' : 'The dictation was archived.'
            );
          })
          .catch(() => {
            this.spinnerService.hide();
            this.toastr.error(
              this.attachmentSwitch.checked ? 'Failed to unarchive the dictation.' : 'Failed to archive the dictation.'
            );
          });
      }
    });
  };
}
