<div class="page">
  <div class="video-player" *ngIf="attachmentSwitch.checked">
    <vg-player (onPlayerReady)="onPlayerReady($event)">
      <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

        <vg-mute></vg-mute>
        <vg-volume></vg-volume>

        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
      <video
        #media
        [vgMedia]="media"
        [src]="selectedVideo?.url"
        id="singleVideo"
        preload="auto"
        autoplay
        crossorigin
      ></video>
    </vg-player>
  </div>

  <div class="page-title__bar">
    <div>
      <h2 class="title">{{ attachmentSwitch.checked ? "Video" : "Image" }} Content</h2>
    </div>

    <div class="filter__panel btn-demo">
      <div class="d-flex align-items-center">
        <p class="mb-0 mr-2">Images</p>
        <ui-switch color="#073786" #attachmentSwitch (change)="onChangeAttachmentSwitch()"></ui-switch>
        <p class="mb-0 ml-2">Videos</p>

        <button class="ml-4 btn add-user-btn" (click)="uploadAttachment()">
          Upload {{ attachmentSwitch.checked ? "Video" : "Image" }}
        </button>
      </div>
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
      <div class="right__panel calendar-data__wrapper">
        <div class="table-data__wrapper table-responsive calendar-data--table">
          <app-table-pagination-client
            [headers]="tableHeaders"
            [data]="attachmentCompleteTableData"
            [hasActions]="true"
            [deleteFunction]="deleteAttachment"
            [downloadFunction]="downloadAttachment"
            [playContent]="playAttachment"
            [pauseContent]="pauseAttachment"
            [pageSize]="paginationPageSize"
            [currentPage]="1"
          >
          </app-table-pagination-client>
        </div>
      </div>
    </div>
  </div>
</div>
