<h1>
  <h1 *ngIf="!isSuperAdmin">Practice ID:</h1>
  {{ adminData.practice_id }}
</h1>
<div class="form__wrapper">
  <form [formGroup]="practiceForm">
    <!-- Practice ID -->
    <div *ngIf="isSuperAdmin" class="form-group">
      <input
        type="text"
        class="form-control"
        formControlName="practice_id"
        placeholder="Practice ID"
      />
    </div>
    <!-- Practice Name -->
    <div class="form-group mb-1">
      <input
        type="text"
        class="form-control"
        formControlName="name"
        placeholder="Practice Name"
      />
    </div>

    <!-- Practice Admin User Information -->
    <ng-container>
      <h3>Admin for Practice</h3>
      <!-- Username -->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          formControlName="practiceAdminUsername"
          placeholder="Username"
        />
      </div>
      <div class="form-group mb-1">
        <input
          type="text"
          class="form-control"
          formControlName="practiceAdminPassword"
          placeholder="Password"
        />
      </div>
    </ng-container>

    <!-- Color Scheme Selection Form -->
    <ng-container>
      <h3>Color Scheme Selection</h3>
      <div class="form-group mb-1">
        <input
          id="defaultColors"
          value="defaultColors"
          type="radio"
          formControlName="colorSelectRadio"
          (change)="colorSchemeChange(false)"
        />
        <label class="ml-4" for="defaultColors">Default Colors</label>

        <input
          class="ml-4"
          id="customColors"
          value="customColors"
          type="radio"
          formControlName="colorSelectRadio"
          (change)="colorSchemeChange(true)"
        />
        <label class="ml-4" for="customColors">Custom Colors</label>
      </div>

      <ng-container *ngIf="useCustomColors">
        <h3>Color Configuration</h3>
        <ng-container>
          <div class="form-group mb-1" style="display: flex">
            <h5>Patient Complete</h5>
            <input
              type="text"
              class="form-control"
              formControlName="patientComplete"
            />
            <input
              [(colorPicker)]="colors.patientComplete"
              [style.background]="colors.patientComplete"
              [value]="colors.patientComplete"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="patientCompleteColor"
            />
          </div>
          <div class="form-group mb-1" style="display: flex">
            <h5>Ready For Provider</h5>
            <input
              type="text"
              class="form-control"
              formControlName="readyForProvider"
            />
            <input
              [(colorPicker)]="colors.readyForProvider"
              [style.background]="colors.readyForProvider"
              [value]="colors.readyForProvider"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="readyForProviderColor"
            />
          </div>
          <div class="form-group mb-1" style="display: flex">
            <h5>Status 4</h5>
            <input type="text" class="form-control" formControlName="status4" />
            <input
              [(colorPicker)]="colors.status4"
              [style.background]="colors.status4"
              [value]="colors.status4"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="status4Color"
            />
          </div>
          <div class="form-group mb-1" style="display: flex">
            <h5>Status 5</h5>
            <input type="text" class="form-control" formControlName="status5" />
            <input
              [(colorPicker)]="colors.status5"
              [style.background]="colors.status5"
              [value]="colors.status5"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="status5Color"
            />
          </div>
          <div class="form-group mb-1" style="display: flex">
            <h5>Status 6</h5>
            <input type="text" class="form-control" formControlName="status6" />
            <input
              [(colorPicker)]="colors.status6"
              [style.background]="colors.status6"
              [value]="colors.status6"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="status6Color"
            />
          </div>
          <div class="form-group mb-1" style="display: flex">
            <h5>Status 7</h5>
            <input type="text" class="form-control" formControlName="status7" />
            <input
              [(colorPicker)]="colors.status7"
              [style.background]="colors.status7"
              [value]="colors.status7"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="status7Color"
            />
          </div>
          <div class="form-group mb-1" style="display: flex">
            <h5>Status 11</h5>
            <input
              type="text"
              class="form-control"
              formControlName="status11"
            />
            <input
              [(colorPicker)]="colors.status11"
              [style.background]="colors.status11"
              [value]="colors.status11"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="status11Color"
            />
          </div>
          <div class="form-group mb-1" style="display: flex">
            <h5>Status 12</h5>
            <input
              type="text"
              class="form-control"
              formControlName="status12"
            />
            <input
              [(colorPicker)]="colors.status12"
              [style.background]="colors.status12"
              [value]="colors.status12"
              [cpOutputFormat]="hex"
              class="form-control"
              formControlName="status12Color"
            />
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Provider CRUD -->
    <ng-container *ngIf="!isSuperAdmin">
      <h3>Providers</h3>
      <div class="center-div">
        <button class="btn btn-primary" (click)="openProvidersPage()">
          Manage Providers
        </button>
      </div>
    </ng-container>

    <!-- Database Selection Form -->
    <ng-container>
      <h3>Database Selection</h3>
      <div class="form-group mb-1">
        <input
          id="defaultDB"
          value="defaultDB"
          type="radio"
          formControlName="dbSelectRadio"
          (change)="dbSelectionChange(false)"
        />
        <label class="ml-4" for="defaultDB">Default Database</label>

        <input
          class="ml-4"
          id="customDB"
          value="customDB"
          type="radio"
          formControlName="dbSelectRadio"
          (change)="dbSelectionChange(true)"
        />
        <label class="ml-4" for="customDB">Custom Database</label>
      </div>

      <ng-container *ngIf="useCustomDB">
        <h3>Database Configuration</h3>
        <ng-container *ngIf="!testCustomDB">
          <div class="form-group mb-1">
            <input
              type="text"
              class="form-control"
              formControlName="dbUsername"
              placeholder="Database login Username"
            />
          </div>
          <div class="form-group mb-1">
            <input
              type="password"
              class="form-control"
              formControlName="dbPassword"
              placeholder="Database login password"
            />
          </div>
          <div class="form-group mb-1">
            <input
              type="text"
              class="form-control"
              formControlName="dbName"
              placeholder="Database Name"
            />
          </div>
          <div class="form-group mb-1">
            <input
              type="text"
              class="form-control"
              formControlName="dbHost"
              placeholder="Database Host"
            />
          </div>
          <div class="form-group mb-1">
            <input
              type="text"
              class="form-control"
              formControlName="dbDialect"
              placeholder="Database Dialect e.g. mssql"
            />
          </div>
          <div class="form-group mb-1">
            <input
              type="number"
              class="form-control"
              formControlName="dbPort"
              placeholder="Database PORT number"
            />
          </div>
        </ng-container>
        <div class="center-div">
          <button
            *ngIf="!testCustomDB"
            class="btn btn-primary mt-20 mb-20"
            (click)="testDatabaseConnection()"
          >
            Test Database Connection
          </button>
          <p *ngIf="testCustomDB" style="color: green">
            Connection Established
          </p>
        </div>
      </ng-container>
    </ng-container>
    <div class="center-div">
      <button
        *ngIf="testCustomDB"
        [disabled]="practiceForm.invalid"
        type="button"
        type="submit"
        class="btn btn-primary mt-20 mb-20"
        (click)="submit()"
      >
        {{ isSuperAdmin ? "Add" : "Update" }} Practice
      </button>
    </div>
  </form>
</div>
