import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderModel } from 'src/app/shared/models/order';
import { OrderService } from 'src/app/shared/services/order.service';
import { FirebaseService } from 'src/app/shared/services/firebase.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css'],
})
export class OrderComponent implements OnInit {
  inputModel: OrderModel;
  panelOpenState = false;
  assistantInfo: any;
  orderInfo: any;
  patientInfo: any;
  patientGroups: any;
  orderSubgroups: any;
  rlb: string;
  procedure_description: string;
  files: any;

  @Input() practice_id: string;
  @Input() provider_id: string;
  @Input() patient_id: string;
  @Output() showHideOrder = new EventEmitter<boolean>();
  @Output() orderId: EventEmitter<string> = new EventEmitter();
  constructor(
    private orderService: OrderService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private firebaseService: FirebaseService
  ) {
    this.files = [];
    this.inputModel = new OrderModel();
  }

  ngOnInit(): void {
    this.inputModel = new OrderModel();
    // this.practice_id = 'Practice 1',
    //   this.provider_id = '387DC9D2-F3CF-4E89-B0BA-6D51C1AEA289',
    //   this.patient_row_id = '4010A59D-4B84-4601-975C-61E1BC0F030C'
    this.inputModel.provider_id = this.provider_id;
    this.inputModel.patient_id = this.patient_id;
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    if (loggedInUser) {
      this.inputModel.created_by = loggedInUser.uid;
    }
    this.rlb = '';
    this.setOrderSummary();
    this.inputModel.procedure_time = '00:00';
    this.inputModel.steps.push('Authorization');
    // this.inputModel.steps.push('Scheduling');
    this.assistantInfo = this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getOrderInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.practice_id || changes.provider_id || changes.patient_id) {
      if (!this.inputModel) {
        this.inputModel = new OrderModel();
      }
      this.inputModel.provider_id = this.provider_id;
      this.inputModel.patient_id = this.patient_id;
      this.getOrderInfo();
    }
  }

  onFileDeleted(index) {
    this.files.splice(index, 1);
  }
  onFileSelected(event) {
    this.spinnerService.show();
    if (event.target.files.length < 1) {
      return;
    }
    this.orderService
      .uploadAttachment(
        event.target.files,
        this.practice_id,
        this.assistantInfo.__zone_symbol__value.client_id,
        0,
        false
      )
      .subscribe(
        (resp) => {
          if (resp.data) {
            this.spinnerService.hide();
            this.toastr.success('File has been uploaded successfully');
            for (let i = 0; i < resp.data.length; i++) {
              const obj = {
                file_name: resp.data[i].file_name,
                file_url: resp.data[i].file_url,
              };
              this.files.push(obj);
            }
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.toastr.error(error);
        }
      );
  }

  setChangedItem(groupId) {
    //some logic
    let array = this.patientGroups.find((x) => x.id === groupId).orderSubgroups;
    this.orderSubgroups = array;
  }

  setProcedureVal(procId, description) {
    this.inputModel.procedure_id = procId;
    this.procedure_description = description;
    this.setOrderSummary();
  }

  checkUncheckOptional(value) {
    this.rlb = value;
    this.setOrderSummary();
  }

  getOrderInfo() {
    this.spinnerService.show();
    this.orderService
      .getOrderByPatientRowId(this.practice_id, this.provider_id, this.patient_id)
      .then((response: any) => {
        if (response.data) {
          this.orderInfo = response.data;
          this.patientInfo = response.data.patient;
          if (this.patientInfo.date_of_birth) {
            this.patientInfo.date_of_birth = moment(this.patientInfo.date_of_birth).format('MM/DD/YYYY');
          }

          this.patientGroups = response.data.groups;
          this.spinnerService.hide();
        } else {
          this.toastr.error(response.message);
        }
      })
      .catch((err) => {
        this.spinnerService.hide();
        console.log(err);
        this.toastr.error(err.message);
      });
  }

  checkorderSteps(event) {
    if (event.target.checked) {
      this.inputModel.steps.push(event.target.defaultValue);
    } else {
      this.inputModel.steps = this.inputModel.steps.filter((x) => x !== event.target.defaultValue);
    }
  }

  setOrderSummary() {
    if (this.procedure_description) {
      this.inputModel.order_summary = this.rlb + ' ' + this.procedure_description;
    } else this.inputModel.order_summary = this.rlb;
  }

  saveOrder() {
    if (!this.inputModel.procedure_date) {
      this.toastr.warning('Please select procedure date first.');
      return;
    }
    if (!this.inputModel.procedure_time) {
      this.inputModel.procedure_time = '00:00';
    }
    this.inputModel.files = this.files;
    this.spinnerService.show();
    this.orderService
      .createOrder(this.inputModel)
      .then((response: any) => {
        this.spinnerService.hide();
        this.toastr.success('Process Completed Successfully');
        this.closeOrder(response);
      })
      .catch((err) => {
        this.spinnerService.hide();
        console.log(err);
        this.toastr.error(err.message);
      });
  }

  closeOrder(response?: any) {
    this.showHideOrder.emit(false);
    if (response && response.data) {
      this.orderId.emit(JSON.stringify(response.data));
    }
  }
}
