<nav class="navbar navbar-expand-lg custom__navbar-nav" style="height: 63px">
  <div class="container-fluid d-flex">
    <div class="d-flex align-items-center" style="width: 65%">
      <i class="fa fa-bars toggle" (click)="toggleMenu()"></i>
      <app-horizontal-notification class="ml-2" *ngIf="userRole === 'practice_admin'"></app-horizontal-notification>
    </div>
    <div id="navbarSupportedContent">
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link user--profile">
            <span class="user-greeting">Hi,</span>
            <span class="profile-username">{{ username }}</span>
            <div class="notification" *ngIf="userRole === 'practice_admin'" role="button" (click)="toggleNotification()">
              <div *ngIf="showNotification">
                <app-notification-pills></app-notification-pills>
              </div>
            </div>
            <button (click)="logout()" class="username__initial btn btn-primary">
              <span class="logout-text">Logout </span><i class="fa fa-sign-out" aria-hidden="true"></i>
            </button>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
