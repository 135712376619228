import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GenericResp, PracticeInfoForUser } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient, private db: AngularFireDatabase) {}

  getUsers(practiceId: string): any {
    return this.db
      .list(`/vsa-assistants/${practiceId.toLowerCase()}/`)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as object),
          }))
        )
      );
  }

  toggleUserStatus(practiceId: string, uid: string, status: string): any {
    const dbRef = this.db.database.ref(`vsa-assistants/${practiceId.toLowerCase()}/${uid}`);
    if (status === 'enable') {
      dbRef.update({ is_disabled: false });
    }
    if (status === 'disable') {
      dbRef.update({ is_disabled: true });
    }
  }

  async getLocationUsers(token: string): Promise<any> {
    return await this.http
      .get<any>(`${environment.LOCATION}/location-users`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .toPromise();
  }

  getFilteredUsers(practiceId: string, numberItems: number, startKey: string): any {
    return this.db.database
      .ref(`/vsa-assistants/`)
      .child(practiceId.toLowerCase())
      .orderByChild('email')
      .limitToFirst(numberItems)
      .startAt(startKey)
      .endAt(startKey + '\uf8ff')
      .get();
  }

  deleteUser(uid: string, practice_id: string): any {
    return this.http.post(`${environment.FIREBASE}/user/delete`, { uid, practice_id }).toPromise();
  }

  addUser(
    email: string,
    password: string,
    practiceData: PracticeInfoForUser,
    firstName: string,
    lastName: string,
    phoneNo: string,
    faxNo: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipCode: string,
    client_id: string,
    client_name: string
  ): any {
    return this.http
      .post(`${environment.FIREBASE}/user/add`, {
        email,
        password,
        practiceData,
        firstName,
        lastName,
        phoneNo,
        faxNo,
        address1,
        address2,
        city,
        state,
        zipCode,
        client_id,
        client_name,
      })
      .toPromise();
  }

  updateUser(uid: string, role: string, practice_id): any {
    return this.http
      .put(`${environment.FIREBASE}/user`, {
        uid,
        data: { role, practice_id },
      })
      .toPromise();
  }

  async inviteUser(data: { email: string }, token: string): Promise<GenericResp> {
    return await this.http
      .post<GenericResp>(`${environment.AUTH}/location/invite-location-user-email`, data, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .toPromise();
  }

  async deleteLocationUser(user_id: string, token: string): Promise<GenericResp> {
    return await this.http
      .delete<GenericResp>(`${environment.AUTH}/location/user/${user_id}`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .toPromise();
  }

  async disableUser(user_id: string, token: string): Promise<GenericResp> {
    return await this.http
      .post<GenericResp>(
        `${environment.AUTH}/disable-user`,
        { user_id },
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .toPromise();
  }

  async enableUser(user_id: string, token: string): Promise<GenericResp> {
    return await this.http
      .post<GenericResp>(
        `${environment.AUTH}/enable-user`,
        { user_id },
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .toPromise();
  }

  async resetPassword(password: string, token: string, firebase_uid?: string, user_id?: string): Promise<any> {
    if (firebase_uid !== undefined) {
      return await this.http
        .post<any>(
          `${environment.AUTH}/reset-password`,
          { password, firebase_uid },
          {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
          }
        )
        .toPromise();
    } else {
      return await this.http
        .post<any>(
          `${environment.AUTH}/reset-password`,
          { user_id, password },
          {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
          }
        )
        .toPromise();
    }
  }
}
