import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { PayerMppr } from '../../models';

@Component({
  selector: 'app-table-draggable',
  templateUrl: './table-draggable.component.html',
  styleUrls: ['./table-draggable.component.scss'],
})
export class TableDraggableComponent {
  @ViewChild('table') table: MatTable<any>;
  @Input() headers: string[];
  @Input() dataSource: any[] | any;
  @Input() totalAllowable: number;
  @Input() payerMPPR: PayerMppr[] = null;

  @Input() addContent: (args: any) => void = null;
  @Input() calculateAllowable: (...args: any) => void = null;
  @Input() reorderContent: (...args: any) => void = null;
  @Input() deleteFunction: (args: any) => void = null;
  @Input() hasActions: boolean = true;
  dropTable(event: CdkDragDrop<any[]>) {
    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
    this.table.renderRows();

    Object.keys(this.dataSource).forEach((key) => {
      this.dataSource[key].mppr = this.payerMPPR[key].mppr;
      this.dataSource[key].proc_order = this.payerMPPR[key].proc_order;
      this.dataSource[key].allowable = this.calculateAllowable(
        +this.dataSource[key].contract,
        +this.dataSource[key].mppr
      );
    });

    this.reorderContent(this.dataSource);
  }
}
