import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/shared/services/order.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Appointment } from '../../models/appointment';
import * as moment from 'moment';

const DATE: Date = new Date();

@Component({
  selector: 'app-add-appointments',
  templateUrl: './add-appointments.component.html',
  styleUrls: ['./add-appointments.component.css']
})
export class AddAppointmentsComponent implements OnInit {

  @Input() clientLocations: any;
  @Input() practiceProviders: any;
  @Input() order_id: string;
  @Input() assistantUID: string;
  @Output() showHideAppointment = new EventEmitter<boolean>();
  @Output() appointmentObj: EventEmitter<string> = new EventEmitter();

  showFacility: boolean = false;
  newFacility: string;
  inputModel: Appointment;
  errors: string = '';
  // orderDatePickerModel: Date;
  @ViewChild('appointment_date') appointment_date: ElementRef;
  model;
  app_time = { hour: 12, minute: 0 };
  constructor(public activeModal: NgbActiveModal,
    private orderService: OrderService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.inputModel = new Appointment();
    this.inputModel.location = this.clientLocations[0].description;
    this.inputModel.notify_patient = true;
    this.inputModel.created_by = this.assistantUID;
  }


  changeLocation(event: any): void {
    const location = event.target.value;
    this.showFacility = false;
    if (location !== 'Add New Item') {
      this.newFacility = location;
    } else {
      this.showFacility = true;
    }
  }

  facilityItem(event: any) {
    this.newFacility = event.target.value;
  }

  validateAppointmentData() {
    this.errors = '';
    // if (this.inputModel.provider_id === '') {
    //   this.errors = 'Please select provider.';
    //   return this.errors;
    // }
    if ((this.showFacility && this.inputModel.location === 'Add New Item' && !this.inputModel.facility)) {
      this.errors = 'Invalid or Missing Data.';
      return this.errors;
    }
    if (!this.inputModel.event_type || !this.appointment_date.nativeElement.value) {
      this.errors = 'Invalid or Missing Data.';
      return this.errors;
    }

    if (this.showFacility) {
      this.inputModel.location = this.inputModel.facility;
    }
    var x
    x = new Date(this.appointment_date.nativeElement.value);
    this.inputModel.appointment_date = x;

    let b = new Date(x);
    b.setHours(this.app_time.hour);
    b.setMinutes(this.app_time.minute);
    this.inputModel.appointment_time = moment(b.toString()).format( 'YYYY-MM-DDTHH:mm:ss') + '.000Z';
    if (!this.inputModel.appointment_time) {
      this.errors = 'Invalid or Missing Data.';
      return this.errors;
    }

    if (this.inputModel.appointment_date < new Date()) {
      this.errors = this.errors + `\n` + `Appointment Date can't be in the past`;
      return this.errors;
    }
    if (this.inputModel.provider_id === '') {
      this.inputModel.provider_id = undefined;
    }
    this.spinnerService.show();
    this.orderService
      .addOrderAppointment(this.order_id, this.inputModel)
      .then((resp: any) => {
        this.spinnerService.hide();
        this.toastr.success("Appointment created successfully!")
        this.activeModal.close(resp)
      })
      .catch(() => {
        this.toastr.error("Could't complete request due to error");
        this.spinnerService.hide();
      });
  }

}
