  <a [href]="navbarComponents[10].activePath">
    <div class="menu-item">
      <i class="fa fa-comment-o" aria-hidden="true"></i>
      <span class="hideText"> Unread Messages </span>
      <span #unreadMessages id="unreadMessages" class="badge ml-1">{{ this.msgLength }}</span>
    </div>
  </a>
  <a [href]="navbarComponents[4].activePath">
    <div class="menu-item ml-3">
      <i class="fa fa-play" aria-hidden="true"></i>
      <span class="hideText"> Video Dictation </span>
      <span #videoDictation class="badge ml-1" id="videoDictation">{{ this.video }}</span>
    </div>
  </a>
  <a [href]="navbarComponents[5].activePath">
    <div class="menu-item ml-3">
      <i class="fa fa-microphone" aria-hidden="true"></i>
      <span class="hideText"> Audio Dictation </span>
      <span class="badge ml-1">{{ this.audio }}</span>
    </div>
  </a>
  <a [href]="['/dashboard']">
    <div class="menu-item ml-3">
      <i class="fa fa-video-camera" aria-hidden="true"></i>
      <span class="hideText"> Live Session </span>
      <span class="badge ml-1">0</span>
    </div>
  </a>
