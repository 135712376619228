/**
 * A Pipe that replaces negative number with zero.
 * Why? => Sometimes, there is a delay with firebase relatime db subscribe method, and somehow it returns -ve number for timer
 *         of user waiting for call.
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noNegNumber',
})
export class NoNegNumberPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value && value.includes('-')) {
      return '0:00';
    }

    return value;
  }
}
