import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/shared/services/order.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rename-modal',
  templateUrl: './rename-modal.component.html',
  styleUrls: ['./rename-modal.component.scss']
})
export class RenameModalComponent implements OnInit {

  @Input() first_name: string;
  @Input() last_name: string;

  model: any;
  searching = false;
  searchFailed = false;

  constructor(
    public activeModal: NgbActiveModal,
    private orderService: OrderService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {}
  ngOnInit(): void {}
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  EditBotton() {
    const data = {
      first_name: this.first_name.trim(),
      last_name : this.last_name.trim()
    }
    if(data.first_name.length < 2 || data.last_name.length < 2){
      this.toastr.error("please add minimum 2 characters in both fields");
      return
    }else if(this.containsSpecialChars(data.first_name) || this.containsSpecialChars(data.last_name) || /\d/.test(data.first_name) ||  /\d/.test(data.last_name)){
      this.toastr.error("special characters and numbers are not allowed");
    }
    else{
     this.activeModal.close(data)
  }}

}
