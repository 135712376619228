import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class ContentManagementService {
  constructor(
    private appService: AppService,
    private spinnerService: NgxSpinnerService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}

  getAttachmentUrl(filePath: string): any {
    return this.storage.refFromURL(filePath).getDownloadURL().toPromise();
  }

  deleteAttachment(
    practiceId: string,
    dbKey: string,
    filePath: string,
    fileType: string
  ): any {
    // Deleting Database Record
    this.spinnerService.show();
    this.db.database
      .ref()
      .child(`/vsa-attachments/${fileType}/${practiceId}/${dbKey}`)
      .remove()
      .then((res) => {
        // Deleting Video Attachment file in storage
        this.storage
          .refFromURL(filePath)
          .delete()
          .subscribe((res) => {
            this.spinnerService.hide();
            this.appService.showToast('Video Attachment Deleted', 'success');
          });
      })
      .catch((err) => {
        this.spinnerService.hide();
        this.appService.showToast("Attachment couldn't be deleted", 'error');
      });
  }
}
