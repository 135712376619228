import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SMSService } from '../../services/sms.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-send-message-modal',
  templateUrl: './send-message-modal.component.html',
  styleUrls: ['./send-message-modal.component.css'],
})
export class SendMessageModalComponent implements OnInit {
  phoneNumber: string = '';
  message: string = '';
  messageType: string = '';
  @ViewChild('talkjsContainer') talkjsContainer!: ElementRef;
  user: any;

  constructor(
    public activeModal: NgbActiveModal,
    private smsService: SMSService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  onMessageTypeChange() {
    if (this.messageType === 'User Message') {
      this.toastr.info('If the user does not exist, the message will be converted to a text message.');
    }
  }

  formatPhoneNumber(event: Event) {
    const inputChar = (event.target as HTMLInputElement).value;
    const cleaned = inputChar.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      this.phoneNumber = `${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${
        match[3] ? `-${match[3]}` : ''
      }`.trim();
    }
  }

  async sendMessage() {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

    if (this.phoneNumber.trim() !== '' && this.message.trim() !== '' && this.messageType.trim() !== '') {
      if (!phoneRegex.test(this.phoneNumber.trim())) {
        this.toastr.error('Invalid phone number format');
        return;
      }
      try {
        const e164PhoneNumber = `+1${this.phoneNumber.replace(/\D/g, '')}`;
        this.spinnerService.show();
        const response = await this.smsService.initiateMessage(
          e164PhoneNumber,
          this.message.trim(),
          this.messageType.trim()
        );
        if (response.code === 200) {
          this.toastr.success('Message sent successfully');
          this.activeModal.close();
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toastr.error('Failed to send message');
        }
      } catch {
        this.spinnerService.hide();
        this.toastr.error('Failed to send message');
      }
    } else {
      this.toastr.error('Enter all fields');
    }
  }

  closeModal() {
    this.spinnerService.hide();
    this.activeModal.close();
  }
}
