<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Verahealth | Dashboard</title>
  </head>

  <body class="right-border">
    <div class="wrappers d-flex align-items-stretch">
      <nav [ngClass]="isNavbarVisible ? '' : 'hide'" id="sidebar" class="wrappers" [style]="hyperNavStyle">
        <span class="border-bottom d-flex">
          <a
            href="https://verahealth.app/"
            target="_blank"
            [ngClass]="!showHideToggle && !mobile ? 'logo point' : 'cropped-logo point'"
          >
            <img
              *ngIf="!showHideToggle && !mobile; else croppedLogo"
              src="../../assets/Verahealth-Logo.png"
              [@fadeIn]
              alt="Verahealth"
            />
            <ng-template #croppedLogo>
              <img src="../../assets/Verahealth-Logo@cropped.png" class="cropped" [@fadeIn] alt="Verahealth" />
            </ng-template>
          </a>
        </span>
        <ul [ngClass]="!showHideToggle ? 'list-unstyled components' : 'list-unstyled components-short'">
          <li
            *ngFor="let comp of navbarComponents"
            [ngClass]="{ active: activePath === comp.activePath }"
            (click)="activePath = comp.activePath"
          >
            <a
              *ngIf="navigateFromDash"
              class="point"
              [href]="[comp.activePath]"
              [style.padding]="hyperLinkPadding"
              [style.font-size]="hyperLinkFontSize"
              [style.line-height]="hyperLinkLineHeight"
            >
              <img class="simple-icon" [src]="comp.svgSrc" alt="dashboard" />
              <img class="active-icon" [src]="comp.activeSvgSrc" alt="dashboard" />
              <span style="margin-right: 2px" class="small-menu" [style]="smallClass">{{ comp.title }}</span>
            </a>
            <a
              *ngIf="!navigateFromDash"
              class="point"
              [routerLink]="[comp.activePath]"
              [style.padding]="hyperLinkPadding"
              [style.font-size]="hyperLinkFontSize"
              [style.line-height]="hyperLinkLineHeight"
            >
              <img class="simple-icon" [src]="comp.svgSrc" alt="dashboard" />
              <img class="active-icon" [src]="comp.activeSvgSrc" alt="dashboard" />
              <span style="margin-right: 2px" class="small-menu" [style]="smallClass">{{ comp.title }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </body>
</html>
