import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  GET_CREATE_SMS_SCRIPT_RESP,
  GET_ALL_SMS_SCRIPTS_RESP,
  SMSScriptCreateData,
  SMSScriptUpdateData,
} from '../models/smsScripts';
import { GenericResp } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SMSService {
  user = JSON.parse(localStorage.getItem('user'));

  constructor(private http: HttpClient) {}

  async getAllSMSScripts(): Promise<GET_ALL_SMS_SCRIPTS_RESP> {
    return await this.http
      .get<GET_ALL_SMS_SCRIPTS_RESP>(`${environment.BASE_URL}/sms-scripts/client`, {
        headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async getAllSMSLogs(): Promise<any> {
    return await this.http
      .get<any>(`${environment.BASE_URL}/practice/sms-logs`, {
        headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async getMonthlyPrice(data: any): Promise<any> {
    return await this.http
      .post<any>(
        `${environment.BASE_URL}/practice/sms/expenses`,
        { startDate: data.startDate, endDate: data.endDate },
        {
          headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
        }
      )
      .toPromise();
  }

  async createSMSScript(data: SMSScriptCreateData): Promise<GET_CREATE_SMS_SCRIPT_RESP> {
    return await this.http
      .post<GET_CREATE_SMS_SCRIPT_RESP>(`${environment.BASE_URL}/sms-scripts/create`, data, {
        headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async updateSMSScript(script_id: string, data: SMSScriptUpdateData): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.BASE_URL}/sms-scripts/${script_id}`, data, {
        headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async deleteSMSScript(script_id: string): Promise<GenericResp> {
    return await this.http
      .delete<GenericResp>(`${environment.BASE_URL}/sms-scripts/${script_id}`, {
        headers: { Authorization: `${this.user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async initiateMessage(phoneNumber: string, smsBody: string, messageType: string): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.stsTokenManager.accessToken}`
    });
    return await this.http
      .post<any>(`${environment.BASE_URL}/web/send-sms-message`, { phoneNumber, smsBody, messageType }, { headers })
      .toPromise();
  }
}
