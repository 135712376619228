<div class="page-title__bar">
  <h2 class="title">Unlock Codes</h2>
  

  <div class="user-actions">
    <button (click)="addUnlockCode()" class="btn add-user-btn">
      Add Unlock Code<i class="fa fa-plus fa-lg ml-2" aria-hidden="true"></i>
    </button>
  </div>
</div>


<app-table-pagination-client
  [headers]="tableHeaders"
  [data]="unlockCodes"
  [hasActions]="true"
  [deleteFunction]="deleteUnlockCode"
  [pageSize]="6"
  [currentPage]="1"
>
</app-table-pagination-client>
