import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/Storage';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { AdminComponent } from './admin/admin.component';
import { PracticeFormComponent } from './admin/practice-form/practice-form.component';
import { ProviderManagementComponent } from './admin/provider-management/provider-management.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';
import * as SentryBrowser from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AuthInterceptor } from './auth/auth.interceptor';

const serverErrorsRegex = new RegExp(
  `500 Internal Server Error|400 Bad Request|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
  'mi'
);

if (environment.SENTRY_ENV === 'test' || environment.SENTRY_ENV === 'production') {
  SentryBrowser.init({
    dsn: environment.DNS,
    environment: environment.SENTRY_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new SentryBrowser.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
  });
  // Sentry.init({
  //   dsn: environment.DNS,
  //   // environment: environment.SENTRY_ENV,
  //   ignoreErrors: [serverErrorsRegex],
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: ['localhost', 'https://random.westus.cloudapp.azure.com:4042/'],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //   ],
  //   tracesSampleRate: 1.0,
  // });
  if (JSON.parse(localStorage.getItem('user'))) {
    SentryBrowser.configureScope((scope) => {
      scope.setUser({
        email: JSON.parse(localStorage.getItem('user')).email,
        Id: JSON.parse(localStorage.getItem('user')).uid,
        displayName: JSON.parse(localStorage.getItem('user')).displayName,
      });
    });
  }
  // Sentry.init({
  //   dsn: environment.DNS,
  //   environment: environment.SENTRY_ENV,
  //   ignoreErrors: [serverErrorsRegex],
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: ['localhost', 'https://random.westus.cloudapp.azure.com:4042/'],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //   ],
  //   tracesSampleRate: 1.0,
  // });
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    PracticeFormComponent,
    ProviderManagementComponent,
    // OrderComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    SharedModule,
    AppRoutingModule,
    DashboardModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
  ],
  exports: [BrowserModule, NgbModule, FormsModule],
  // providers: [AngularFireStorage],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: [APP_INITIALIZER, AngularFireStorage],
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
