<form>
  <div class="form-group mb-0">
    <label class="col-md-2 font-bold">Name:</label>
    <label class="col-md-4 font-bold" *ngIf="patientInfo">
      {{ patientInfo.first_name }}
    </label>
    <label class="col-md-2 font-bold">DOB:</label>
    <label class="col-md-4 font-bold" *ngIf="patientInfo">
      {{ patientInfo.date_of_birth }}
    </label>
  </div>
  <div class="form-group mb-0">
    <label class="col-md-2 font-bold">MRN:</label>
    <label class="col-md-4 font-bold" *ngIf="patientInfo">
      {{ patientInfo.med_rec_nbr }}
    </label>
    <!-- <label class="col-md-2 font-bold">Room:</label>
    <label class="col-md-4 font-bold" *ngIf="patientInfo">
      {{ patientInfo.patient_room }}
    </label> -->
  </div>
  <!-- <div class="form-group">
    <label class="col-md-2 font-bold">Provider:</label>
    <label class="col-md-8 font-bold" *ngIf="patientInfo && patientInfo.provider">
      {{ patientInfo.provider.description }}
    </label>
  </div> -->
  <div class="form-group">
    <ng-container *ngFor="let group of patientGroups">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          id="group.id"
          value="group.id"
          name="group"
          (change)="setChangedItem(group.id)"
        />
        <label class="form-check-label" for="{group.id}">
          {{ group.description }}
        </label>
      </div>
    </ng-container>
  </div>
  <div class="form-group">
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="Right"
        name="group1"
        value="Right"
        (change)="checkUncheckOptional('Right')"
        [(ngModel)]="rlb"
        [checked]="rlb === 'Right'"
      />
      <label class="form-check-label" for="Right">Right</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="Left"
        name="group1"
        value="Left"
        (change)="checkUncheckOptional('Left')"
        [(ngModel)]="rlb"
        [checked]="rlb === 'Left'"
      />
      <label class="form-check-label" for="Left">Left</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="Bilateral"
        name="group1"
        value="Bilateral"
        (change)="checkUncheckOptional('Bilateral')"
        [(ngModel)]="rlb"
        [checked]="rlb === 'Bilateral'"
      />
      <label class="form-check-label" for="Bilateral">Bilateral</label>
    </div>
  </div>
  <div class="form-group">
    <mat-accordion *ngFor="let obj of orderSubgroups">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ obj.description }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-group">
          <div class="form-check form-check-inline ml-2" *ngFor="let proc of obj.orderProcedures">
            <input
              class="form-check-input"
              type="radio"
              id="proc.id"
              value="proc.id"
              name="proc"
              (change)="setProcedureVal(proc.id, proc.description)"
            />
            <label class="form-check-label" for="{proc.id}">
              {{ proc.description }}
            </label>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="form-group row">
    <label for="order_summary" class="col-sm-4 col-form-label pr-0">Order Summary:</label>
    <div class="col-sm-8 pl-0">
      <input
        type="text"
        class="form-control"
        name="order_summary"
        id="order_summary"
        [(ngModel)]="inputModel.order_summary"
      />
    </div>
  </div>

  <div class="form-group row">
    <label for="procedure_date" class="col-sm-4 col-form-label pr-0">Procedure Date:</label>
    <div class="col-sm-8 pl-0">
      <input
        type="date"
        class="form-control"
        name="procedure_date"
        id="procedure_date"
        [(ngModel)]="inputModel.procedure_date"
      />
    </div>
  </div>
  <div class="form-group row">
    <label for="procedure_time" class="col-sm-4 col-form-label pr-0">Procedure Time:</label>
    <div class="col-sm-8 pl-0">
      <input
        type="time"
        class="form-control"
        name="procedure_time"
        id="procedure_time"
        [(ngModel)]="inputModel.procedure_time"
      />
    </div>
  </div>
  <div class="form-group">
    <h6>Order Steps</h6>
  </div>
  <div class="form-group">
    <label class="col-md-6">
      <input type="checkbox" value="PCP Clearance" (change)="checkorderSteps($event)" />
      PCP Clearance
    </label>
    <label class="col-md-6">
      <input type="checkbox" value="Routine Labs" (change)="checkorderSteps($event)" />
      Routine Labs
    </label>
    <label class="col-md-6">
      <input type="checkbox" value="Cardiac Clearance" (change)="checkorderSteps($event)" />
      Cardiac Clearance
    </label>
    <label class="col-md-6">
      <input type="checkbox" value="Chest X-Ray" (change)="checkorderSteps($event)" />
      Chest X-Ray
    </label>
    <label class="col-md-6">
      <input type="checkbox" value="Healthy Screening" (change)="checkorderSteps($event)" />
      Healthy Screening
    </label>
    <label class="col-md-6">
      <input type="checkbox" value="EKG" (change)="checkorderSteps($event)" />
      EKG
    </label>
    <label class="col-md-6">
      <input type="checkbox" value="Authorization" checked disabled />
      Authorization
    </label>
    <label class="col-md-6">
      <input type="checkbox" value="Scheduling" (change)="checkorderSteps($event)" />
      Scheduling
    </label>
  </div>
  <div class="form-group">
    <h6>Consent Required?</h6>
  </div>
  <div class="form-group">
    <label class="col-md-6">
      <input type="checkbox" name="is_consent_required" [(ngModel)]="inputModel.is_consent_required" />
      Required Consent
    </label>
  </div>
  <div class="form-group">
    <h6>Notify Patient?</h6>
  </div>
  <div class="form-group">
    <label class="col-md-6">
      <input type="checkbox" name="notify_patient" [(ngModel)]="inputModel.notify_patient" />
      Notify Patient
    </label>
  </div>
  <div class="form-group" style="display: flex">
    <h6>Add Attachments</h6>
    <div class="form-group">
      <label for="file" class="btn-add-attachments">
        <i class="fa fa-plus plus" aria-hidden="true"></i>
      </label>
      <input type="file" id="file" (change)="onFileSelected($event)" multiple />
    </div>
  </div>
  <div class="form-group" (change)="(this.files)">
    <table class="table">
      <thead>
        <tr></tr>
      </thead>
      <tbody *ngFor="let file of this.files; let i = index">
        <tr *ngIf="this.files[i].file_name">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ this.files[i].file_name }}</td>
          <td>
            <button style="background-color: rgb(199, 107, 107)" class="btn del-btn" (click)="onFileDeleted(i)">
              <img src="./../../../../assets/icons/trash.svg" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="form-group text-right">
    <button type="button" class="btn btn-xs btn btn-secondary mr-4" (click)="closeOrder()">Cancel</button>
    <button type="submit" class="btn btn-xs btn-success" (click)="saveOrder()">
      <span>Save</span>
    </button>
  </div>
</form>
