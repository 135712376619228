<div class="right__panel">
  <div class="search__panel btn-demo mb-2" style="padding-right: 10px; margin: 32px 35px 0 0">
    <div class="input-group">
      <input
        type="time"
        class="form-control"
        ngbTooltip="Search by Appt time"
        [(ngModel)]="selectedTime"
        (change)="onTimeChange()"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="clearSelectedTime()">Clear</button>
      </div>
    </div>
  </div>
  <div class="search__panel btn-demo mb-2" style="padding-right: 10px; margin: 32px 10px 0 0">
    <div class="input-group">
      <input
        type="date"
        [(ngModel)]="filterByDate"
        (ngModelChange)="onDateChange()"
        class="form-control"
        ngbTooltip="Search appointments By Date"
        (keydown)="disableTyping($event)"
      />
    </div>
  </div>
  <div class="search__panel btn-demo mb-2" style="padding-right: 10px; margin: 32px 10px 0 0">
    <select ngbTooltip="Search Criteria" [(ngModel)]="searchCriteria">
      <option value="patient_name">Patient Name</option>
      <option value="provider_name">Provider Name</option>
      <option value="event">Event</option>
    </select>
    <div class="input-group">
      <input
        #searchBar
        type="search"
        (keyup.enter)="searchRecords($event)"
        [(ngModel)]="searchKeyword"
        (search)="searchRecords($event, true)"
        class="form-control change"
        placeholder="Search"
      />
      <div class="input-group-append" (click)="searchRecords($event)">
        <span class="input-group-text">
          <img src="../../../assets/icons/daily-screening.svg" alt="search" />
        </span>
      </div>
    </div>
  </div>

  <main class="container-fluid">
    <div class="page-title__bar">
      <div>
        <h2 class="title">Appointments Report</h2>
      </div>

      <div style="margin: 5px 0 0 auto">
        <div class="archived__panel mb-1 btn-demo">
          <div class="d-flex align-items-center">
            <p class="mb-1 ml-2">Non Archived &nbsp;</p>
            <ui-switch color="cyan" #attachmentSwitch (change)="onChangeSwitch()"></ui-switch>
            <p class="mb-1 mr-2 ml-1">Archived</p>
          </div>
        </div>

        <div class="filter__panel mb-1 btn-demo" ngbTooltip="Filter" (click)="openFilterModal()">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [ngClass]="selectedProvider && selectedProvider.id ? 'filtered' : ''"
            alt="filters"
          >
            <path
              d="M18.3334 2.5H1.66669L8.33335 10.3833V15.8333L11.6667 17.5V10.3833L18.3334 2.5Z"
              stroke="#001737"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="filter__panel mb-1 btn-demo" ngbTooltip="Download CSV" (click)="downloadCSV()">
          <i class="fa fa-cloud-download fa-lg" aria-hidden="true"></i>
        </div>
        <div class="filter__panel mb-1 btn-demo" ngbTooltip="Print Data" (click)="printData()">
          <i class="fa fa-print fa-lg" aria-hidden="true"></i>
        </div>
      </div>
      <div class="search__panel btn-demo">
        <select ngbTooltip="Search Criteria" [(ngModel)]="statusFilter" (change)="getAppointmentReports()">
          <option value="Confirmed">Confirmed Appointments</option>
          <option value="Pending">Pending Appointments</option>
          <option value="Cancelled">Cancelled Appointments</option>
        </select>
      </div>
    </div>

    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
        <div class="right__panel calendar-data__wrapper">
          <div class="table-data__wrapper table-responsive calendar-data--table">
            <app-table-pagination-client
              [headers]="appointmentReportsTableHeaders"
              [data]="appointmentReportsTableData"
              [pageSize]="pageSize"
              [currentPage]="1"
              [style]="this.style"
              [orderByFunction]="orderBy"
              [hasActions]="true"
              [archivedFunction]="archiveAppointment"
              [smsCallInfo]="getReminderDetails"
              [paginationFirstPage]="firstPage"
              [paginationPrevPage]="prevPage"
              [paginationNextPage]="nextPage"
              [paginationLastPage]="lastPage"
              [paginationCustomPage]="customPage"
              [serverSidePagination]="tablePages"
            >
            </app-table-pagination-client>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
