import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CREATE_FORM_REQ_BODY,
  GenericResp,
  GET_ALL_CLIENT_FORMS_RESP,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class StatementsService {
  constructor(private http: HttpClient) {}

  async getAllClientStatement(
    practice_id: string,
    client_id: string
  ): Promise<any> {
    return await this.http
      .get<any>(
        `${environment.STATEMENTS}/client/${client_id}/practice/${practice_id}/uploaded`
      )
      .toPromise();
  }

  uploadImage(file, practice_id, clientId): Observable<any> {
    let header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('client_id', clientId);
    formData.append('practice_id', practice_id);
    formData.append('file', file);
    return this.http.post<any>(`${environment.UPLOAD_STATEMENTS}`, formData, {
      headers: header,
    });
  }
  uploadCSV(file): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    let header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(
      `${environment.AUTOPAY_STATEMENTS}/upload`,
      formData,
      {
        headers: { Authorization: `${user.stsTokenManager.accessToken}` },
      }
    );
  }
  getAutopayFiles(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<any>(`${environment.AUTOPAY_STATEMENTS}/files`, {
      headers: { Authorization: `${user.stsTokenManager.accessToken}` },
    });
  }
}
