<div class="table-data__wrapper table-responsive">
  <table class="table table-data" style="margin-bottom: 0px" cdk-table #table [dataSource]="dataSource" cdkDropList
    [cdkDropListData]="dataSource" (cdkDropListDropped)="dropTable($event)">
    <ng-container *ngFor="let header of headers">
      <ng-container [matColumnDef]="header">
          <th *matHeaderCellDef>
            <span *ngIf="header ==='contract_description'">
              Contract Description
            </span>
           <span *ngIf="header !=='contract_description'">
            {{ header }}
           </span> 
          </th>
        <td *matCellDef="let element">
          <span *ngIf="header !=='action'">
            {{ element[header] | customFieldFormat: header}}
          </span>
          <span *ngIf="header==='action'">
            <button class="btn del-btn" (click)="deleteFunction(element)" ngbTooltip="Delete" [openDelay]="400"
              [closeDelay]="400">
              <img src="/assets/icons/trash.svg" />
            </button>
          </span>
        </td>

      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="headers"></tr>
    <tr mat-row *matRowDef="let row; columns: headers" cdkDrag [cdkDragData]="row"></tr>
  </table>
  <table class="table table-data" style="margin-top: -1px" *ngIf="totalAllowable">
    <tr>
      <td><strong>Total</strong></td>
      <td></td>
      <td style="text-align: left;" class="pr-0">${{ totalAllowable }}</td>
    </tr>
  </table>
</div>