import { DateTime } from 'luxon';
import { Assistant, PracticeInfoForUser, User } from './../../shared/models/';
import { UserManagementService } from './../../shared/services/user-management.service';
import { FirebaseService } from './../../shared/services/firebase.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, HostListener, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUserModalComponent } from 'src/app/shared/components/add-user-modal/add-user-modal.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  tableHeaders = ['id', 'email', 'practice', 'joined_on'];
  referralTableHeaders = ['id', 'email', 'joined_on'];
  usersData: User[] = [];
  assistantInfo: Assistant;
  pageSize: number = 8;
  currentPage: number = 1;
  totalPages: number = 0;
  nextKey: any;
  prevKeys: any[] = [];
  subscription: any;
  userRole: string;
  user: any;
  currentUser: any;

  @HostListener('document:keydown.shift.arrowright')
  navNext() {
    if (this.currentPage < this.totalPages) this.currentPage += 1;
    else this.toastr.info('No next page available.');
  }
  @HostListener('document:keydown.shift.control.arrowright')
  navEnd() {
    if (this.currentPage != this.totalPages) this.currentPage = this.totalPages;
    else this.toastr.info('Already on the last page.');
  }
  @HostListener('document:keydown.shift.arrowleft')
  navPrev() {
    if (this.currentPage > 1) this.currentPage -= 1;
    else this.toastr.info('No previous page available.');
  }
  @HostListener('document:keydown.shift.control.arrowleft')
  navStart() {
    if (this.currentPage != 1) this.currentPage = 1;
    else this.toastr.info('Already on the first page.');
  }
  @HostListener('document:keydown.shift.n')
  create() {
    this.addUser();
  }

  constructor(
    private spinnerService: NgxSpinnerService,
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('user'));
    if (!!!data) this.authService.logout();
    this.currentUser = data.email;
    this.init();
  }

  async init(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();
    switch (this.userRole) {
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }

    this.spinnerService.show();
    this.assistantInfo = await this.firebaseService.getAssistant(this.user.uid, this.user.displayName);

    this.spinnerService.hide();
    this.getUsers();
  }

  initUsers(): void {
    this.nextKey = null;
    this.prevKeys = [];
    this.currentPage = 1;
  }

  setTotalPages(pages): void {
    this.totalPages = pages;
  }

  toggleUserStatus = (record: any, status: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#073786',
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.userRole === 'location_admin') {
          if (status === 'enable') {
            this.spinnerService.show();
            this.userManagementService
              .enableUser(record.user_id, this.user.stsTokenManager.accessToken)
              .then((resp) => {
                this.spinnerService.hide();
                this.toastr.success('User Enabled.');
                this.initUsers();
                this.getUsers();
              })
              .catch((err) => {
                this.spinnerService.hide();
                console.error(err);
                this.toastr.error(err.error.message);
              });
          } else if (status === 'disable') {
            this.spinnerService.show();
            this.userManagementService
              .disableUser(record.user_id, this.user.stsTokenManager.accessToken)
              .then((resp) => {
                this.spinnerService.hide();
                this.toastr.success('User Disabled.');
                this.initUsers();
                this.getUsers();
              })
              .catch((err) => {
                this.spinnerService.hide();
                console.error(err);
                this.toastr.error(err.error.message);
              });
          }
        } else if (this.userRole === 'practice_admin' || this.userRole === 'practice_admin_sante_community' ||this.userRole === 'practice_admin_sante_community_order') {
          if (status === 'enable') {
            this.userManagementService.toggleUserStatus(
              this.assistantInfo.practiceId.toLowerCase(),
              record.key,
              'enable'
            );
            this.toastr.success('User Enabled.');
          } else if (status === 'disable') {
            this.userManagementService.toggleUserStatus(
              this.assistantInfo.practiceId.toLowerCase(),
              record.key,
              'disable'
            );
            this.toastr.success('User Disabled.');
          }
        }
      }
    });
  };

  getUsers(key?: string): void {
    if (this.userRole === 'location_admin') {
      this.spinnerService.show();
      this.userManagementService
        .getLocationUsers(this.user.stsTokenManager.accessToken)
        .then((resp) => {
          this.spinnerService.hide();
          this.usersData = resp.data.map((user, index) => ({
            id: ++index,
            email: user.email,
            location_id: user.location_id,
            user_id: user.user_id,
            role: user.is_enabled ? user.role.substr(user.role.indexOf('_') + 1, user.role.length - 1) : 'invited',
            joined_on: moment.parseZone(user.created_at).format('MM/DD/YYYY, hh:mm A'),
            is_disabled: user.is_disabled,
          }));
          const userIndex = this.usersData.findIndex((user) => user.email === this.currentUser);
          if (userIndex !== -1) {
            const userToMove = this.usersData.splice(userIndex, 1)[0];
            this.usersData.unshift(userToMove);
          }
        })
        .catch((err) => {
          this.spinnerService.hide();
          console.error(err);
        });
    } else {
      if (this.subscription) this.subscription.unsubscribe();

      this.spinnerService.show();

      this.subscription = this.userManagementService
        .getUsers(this.assistantInfo.practiceId.toLowerCase())
        .subscribe((data: Assistant[]) => {
          this.spinnerService.hide();
          this.usersData = [];
          this.usersData = data.map((m, index) => ({
            id: index + 1,
            key: m.key,
            email: m.email,
            practice: m.practiceId,
            joined_on: DateTime.fromMillis(+m.createdAt)
              .setZone('America/Los_Angeles')
              .toLocaleString(DateTime.DATETIME_SHORT),
            is_disabled: m.is_disabled,
          }));
          const userIndex = this.usersData.findIndex((user) => user.email === this.currentUser);
          if (userIndex !== -1) {
            const userToMove = this.usersData.splice(userIndex, 1)[0];
            this.usersData.unshift(userToMove);
          }
        });
    }
  }

  filterUsers(keyword: string): void {
    this.initUsers();
    if (keyword.length > 0) {
      this.spinnerService.show();
      if (this.subscription) this.subscription.unsubscribe();

      this.userManagementService
        .getFilteredUsers(this.assistantInfo.practiceId.toLowerCase(), this.pageSize + 1, keyword)
        .then((snapshot) => {
          let data: Assistant[] = snapshot.val();
          if (data) {
            data = Object.keys(data).map((m) => {
              return {
                key: m,
                ...data[m],
              };
            });
          } else {
            data = [];
          }

          let id = this.pageSize * this.currentPage - this.pageSize;
          this.spinnerService.hide();
          this.usersData = [];

          const dataToShow = _.slice(data, 0, this.pageSize);
          this.nextKey = _.get(data[this.pageSize], 'key');

          this.usersData = dataToShow.map((m) => ({
            id: ++id,
            key: m.key,
            email: m.email,
            practice: m.practiceId,
            joined_on: DateTime.fromMillis(+m.createdAt)
              .setZone('America/Los_Angeles')
              .toLocaleString(DateTime.DATETIME_SHORT),
          }));
        })
        .catch((err) => {
          this.spinnerService.hide();
          console.error(err);
        });
    } else {
      this.initUsers();
      this.getUsers();
    }
  }

  deleteUser = (user: User) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#073786',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.userManagementService
          .deleteUser(user.key, user.practice.toLowerCase())
          .then((res) => {
            if (res.code === 200) {
              this.spinnerService.hide();
              this.toastr.success('User has been deleted.');
              this.initUsers();
              this.getUsers();
            } else if (res.code === 500) {
              this.spinnerService.hide();
              this.toastr.error(res.error);
              this.initUsers();
              this.getUsers();
            }
          })
          .catch((err) => {
            this.spinnerService.hide();
            console.error(err);
            this.toastr.error(err.error.message);
          });
      }
    });
  };

  resetPassword = (user: User) => {
    const self = this;
    Swal.fire({
      title: 'Reset Password',
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="Enter Password">' +
        '<input id="swal-input2" class="swal2-input" placeholder="Confirm Password">',
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
        });
      },
      onOpen: function () {
        $('#swal-input1').focus();
      },
    })
      .then(function (result) {
        if (result.value[0] === '' || result.value[1] === '') {
          Swal.fire(`Both input fields are required`);
          return;
        }
        if (result.value[0] !== result.value[1]) {
          Swal.fire(`password did not match`);
          return;
        }
        if (result.value[0].length < 8 || result.value[1].length < 8) {
          Swal.fire(`password needs to be at least 8 characters long`);
          return;
        }

        if (result.value[0] === result.value[1]) {
          let firbase_uid = '';
          let user_id = '';
          if (self.userRole === 'practice_admin' || 'practice_admin_sante_community' || 'practice_admin_sante_community_order') {
            firbase_uid = user.key;
          }
          if (self.userRole === 'location_admin') {
            user_id = user.user_id;
          }
          self.spinnerService.show();
          self.userManagementService
            .resetPassword(result.value[0], self.user.stsTokenManager.accessToken, firbase_uid, user_id.toString())
            .then((resp) => {
              self.spinnerService.hide();
              self.toastr.success('Password Updated');
              self.initUsers();
              self.getUsers();
            })
            .catch((err) => {
              self.spinnerService.hide();
              console.error(err);
              self.toastr.error(err.error.message);
            });
        }
      })
      .catch();
  };

  deleteLocationUser = (user: User) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#073786',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.userManagementService
          .deleteLocationUser(user.user_id, this.user.stsTokenManager.accessToken)
          .then((resp) => {
            this.spinnerService.hide();
            this.toastr.success('User has been deleted.');
            this.initUsers();
            this.getUsers();
          })
          .catch((err) => {
            this.spinnerService.hide();
            console.error(err);
            this.toastr.error(err.error.message);
          });
      }
    });
  };

  editUser = (user: any) => {
    const addUserModal = this.modalService.open(AddUserModalComponent, {
      size: 'lg',
      centered: true,
    });
    addUserModal.componentInstance.altTitle = 'Update User';
    addUserModal.componentInstance.token = this.user.stsTokenManager.accessToken;
    addUserModal.componentInstance.User = user;

    addUserModal.result.then((resp) => {
      if (resp) {
        this.initUsers();
        this.getUsers();
      }
    });
  };

  addUser(): void {
    const addUserModal = this.modalService.open(AddUserModalComponent, {
      size: 'lg',
      centered: true,
    });

    if (this.userRole === 'location_admin') {
      addUserModal.componentInstance.altTitle = 'Invite User';
      addUserModal.componentInstance.inviteForm = true;
      addUserModal.componentInstance.token = this.user.stsTokenManager.accessToken;
    } else addUserModal.componentInstance.assistantInfo = this.assistantInfo;

    addUserModal.result.then((resp) => {
      if (resp) {
        this.initUsers();
        this.getUsers();
      }
    });
  }
}
