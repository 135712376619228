import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { UnlockCodesComponent } from './unlock-codes/unlock-codes.component';
import { ActivityLogsComponent } from './activity-logs/activity-logs.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { VideoDictationComponent } from './video-dictation/video-dictation.component';
import { AudioDictationComponent } from './audio-dictation/audio-dictation.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { AcumenDocumentUploadsComponent } from './acumen-document-uploads/acumen-document-uploads.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { OrderComponent } from './home/order/order.component';
import { MessagesComponent } from './messages/messages.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { AddUserModalComponent } from '../shared/components/add-user-modal/add-user-modal.component';
import { FormsComponent } from './forms/forms.component';
import { CreateFormComponent } from './forms/create-form/create-form.component';
import { StatementsComponent } from './statements/statements.component';
import { OrdersPopupComponent } from './order-management/orders-popup/orders-popup.component';
import { TextMaskModule } from 'angular2-text-mask';
import { PayerBenefitComponent } from './payer-benefit/payer-benefit.component';
import { VsaPreviewFormsModule } from 'vsa-preview-forms';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import * as SentryBrowser from '@sentry/browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PaymentsComponent } from './payments/payments.component';
import { AppointmentReportsComponent } from './appointment-reports/appointment-reports.component';
import { BrandingComponent } from './branding/branding.component';
import { SmsScriptsComponent } from './sms-scripts/sms-scripts.component';
import { TranscriptionsComponent } from './transcriptions/transcriptions.component';
import { MatTabsModule } from '@angular/material/tabs';
// import { SmsBillingComponent } from './sms-billing/sms-billing.component';

const serverErrorsRegex = new RegExp(
  `500 Internal Server Error|400 Bad Request|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
  'mi'
);

if (JSON.parse(localStorage.getItem('user'))) {
  SentryBrowser.configureScope((scope) => {
    scope.setUser({
      email: JSON.parse(localStorage.getItem('user')).email,
      Id: JSON.parse(localStorage.getItem('user')).uid,
      displayName: JSON.parse(localStorage.getItem('user')).displayName,
    });
  });
}

const userRole = JSON.parse(localStorage.getItem('role'));
let children;

switch (userRole) {
  case 'practice_admin':
    children = [
      { path: 'branding', component: BrandingComponent },
      { path: 'unlockcodes', component: UnlockCodesComponent },
      { path: 'activity', component: ActivityLogsComponent },
      { path: 'users', component: UserManagementComponent },
      { path: 'audio-dictation', component: AudioDictationComponent },
      { path: 'video-dictation', component: VideoDictationComponent },
      { path: 'content', component: ContentManagementComponent },
      { path: 'documents', component: AcumenDocumentUploadsComponent },
      { path: 'messages', component: MessagesComponent },
      { path: 'orders', component: OrderManagementComponent },
      { path: 'forms', component: FormsComponent },
      { path: 'forms/create-form', component: CreateFormComponent },
      { path: 'statements', component: StatementsComponent },
      { path: 'payments', component: PaymentsComponent },
      { path: 'payer-benefits', component: PayerBenefitComponent },
      { path: 'transcriptions', component: TranscriptionsComponent },
      { path: 'appointment-reports', component: AppointmentReportsComponent },
      { path: 'sms-scripts', component: SmsScriptsComponent },
      // { path: 'sms-billing', component: SmsBillingComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'practice_admin_sante_community':
    children = [
      { path: 'messages', component: MessagesComponent },
      { path: 'appointment-reports', component: AppointmentReportsComponent },
      { path: 'sms-scripts', component: SmsScriptsComponent },
      { path: 'users', component: UserManagementComponent },
      { path: '', component: HomeComponent },
    ];
    break;

    case 'practice_admin_sante_community_order':
      children = [
        { path: 'messages', component: MessagesComponent },
        { path: 'appointment-reports', component: AppointmentReportsComponent },
        { path: 'sms-scripts', component: SmsScriptsComponent },
        { path: 'users', component: UserManagementComponent },
        { path: 'orders', component: OrderManagementComponent },
        { path: '', component: HomeComponent },
      ];
      break;

  case 'practice_user_sante_community':
    children = [
      { path: 'messages', component: MessagesComponent },
      { path: 'appointment-reports', component: AppointmentReportsComponent },
      { path: '', component: HomeComponent },
    ];
    break;
    case 'practice_user_sante_community_order':
    children = [
      { path: 'messages', component: MessagesComponent },
      { path: 'appointment-reports', component: AppointmentReportsComponent },
      { path: 'orders', component: OrderManagementComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'location_admin':
    children = [
      { path: 'users', component: UserManagementComponent },
      { path: 'orders', component: OrderManagementComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'location_user':
    children = [
      { path: 'orders', component: OrderManagementComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'practice_benefits':
    children = [
      { path: 'orders', component: OrderManagementComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'practice_scribe':
    children = [
      { path: 'video-dictation', component: VideoDictationComponent },
      { path: 'audio-dictation', component: AudioDictationComponent },
      { path: 'transcriptions', component: TranscriptionsComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'practice_general_user':
    children = [
      { path: 'video-dictation', component: VideoDictationComponent },
      { path: 'audio-dictation', component: AudioDictationComponent },
      { path: 'transcriptions', component: TranscriptionsComponent },
      { path: 'orders', component: OrderManagementComponent },
      { path: 'messages', component: MessagesComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'practice_message_user':
    children = [
      { path: 'messages', component: MessagesComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  case 'practice_message_admin':
    children = [
      { path: 'messages', component: MessagesComponent },
      { path: '', component: HomeComponent },
    ];
    break;
  default:
    // For an empty userRole or any other unmatched value
    children = [];
    break;
}

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    children,
  },
];

@NgModule({
  declarations: [
    BrandingComponent,
    HomeComponent,
    DashboardComponent,
    UnlockCodesComponent,
    ActivityLogsComponent,
    UserManagementComponent,
    VideoDictationComponent,
    AudioDictationComponent,
    ContentManagementComponent,
    AcumenDocumentUploadsComponent,
    OrderComponent,
    MessagesComponent,
    OrderManagementComponent,
    AddUserModalComponent,
    FormsComponent,
    CreateFormComponent,
    StatementsComponent,
    OrdersPopupComponent,
    PayerBenefitComponent,
    PaymentsComponent,
    AppointmentReportsComponent,
    SmsScriptsComponent,
    TranscriptionsComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    CanvasWhiteboardModule,
    RouterModule.forChild(routes),
    UiSwitchModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    TextMaskModule,
    VsaPreviewFormsModule,
    CKEditorModule,
  ],
  exports: [RouterModule, OrderComponent, AddUserModalComponent],
})
export class DashboardModule {}
