import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/shared/services/order.service';
import { ServerSidePages, OrderPatient } from 'src/app/shared/models';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { DictationData, Assistant, ProviderTableRecord } from 'src/app/shared/models';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-orders-popup',
  templateUrl: './orders-popup.component.html',
  styleUrls: ['./orders-popup.component.scss'],
  providers: [DatePipe],
})
export class OrdersPopupComponent implements OnInit {
  orderResults: string[] = ['patient_name'];
  CreateOrderData: { uniq_id: string; practice_id: string; provider_id?: string } = null;
  providerTableData: ProviderTableRecord[];
  providerDescriptions: string[] = [];
  selectedOption: string = '';
  selectedProviderId: string;

  showHideOrder: boolean;
  searchKeyword: any;
  searchKeywordName: string = '';
  pageSize: number = 10;
  searchCriteria: string = 'patient_name';
  assistantPractice: string;
  tablePages: ServerSidePages;
  selectedUserPatients: OrderPatient[];
  selectedPatient: OrderPatient;
  appointment_date: any;
  name: any;
  date_of_birth: any;
  assistantInfo: Assistant = null;
  patient_id: any;
  pat_row_id: any;
  userPatientsTableData: any[];
  providers: any[] = [];

  constructor(
    private firebaseService: FirebaseService,
    private spinnerService: NgxSpinnerService,
    private orderService: OrderService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    this.init();
    this.showHideOrder = false;
  }

  ngOnInit(): void {
    this.searchKeyword = new Date();
    this.searchKeyword = this.datePipe.transform(this.searchKeyword, 'yyyy-MM-dd');
    this.getAllPatients();
    this.getAllProviders();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
  }

  filterUsers(keyword: string): void {
    this.searchKeyword = keyword;
    this.getAllPatients();
  }
  
  filterUserByName(keywordName:string): void{
    if (!this.searchKeyword) {
      this.toastr.warning('Please select an appointment date first.');
      return;
    }
    this.searchKeywordName = keywordName;
    this.getAllPatients();
  }

  getAllPatients(page: number = 1, limit: number = 10): void {
    this.spinnerService.show();
    this.orderService.getAllPatients(this.assistantInfo.practiceId, this.searchKeyword,this.searchKeywordName, page, limit).then((resp) => {
      this.spinnerService.hide();
      this.tablePages = {
        firstPage: 1,
        lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
        currentPage: resp.data.currentPage,
        totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
        nextPage: resp.data.nextPage,
        prevPage: resp.data.previousPage,
        total: resp.data.total,
      };
      this.selectedUserPatients = resp.data.data || [];
      this.userPatientsTableData = this.selectedUserPatients.map((pat: any) => ({
        patient_id: pat.patient_row_id,
        patient_row_id: pat.id,
        id: pat.patient_row_id,
        patient_location: pat.practice.practice_id,
        patient_name: pat.patient_name,
        date_of_birth: pat.date_of_birth,
        cell_phone: pat.cell_phone,
        med_rec_nbr: pat.med_rec_nbr,
        first_name: pat.first_name,
        last_name: pat.last_name,
        appointment_date: pat.appointment_date,
      }));
    });
  }

  getAllProviders(): void {
    this.spinnerService.show();
    this.orderService.getProviders(this.assistantInfo.practiceId).subscribe((resp) => {
      this.spinnerService.hide();
      if (resp.code === 200) {
        this.providerTableData = resp.data.map((m) => ({
          ID: m.provider_id,
          Name: m.description,
          Practice: m.practice_id,
        }));
        this.providerDescriptions = resp.data.map((m) => m.description).sort((a, b) => a.localeCompare(b));
      } else if (resp.code === 500) {
        this.toastr.error("Couldn't fetch data due to server error");
      }
    });
  }

  selectPatient = (pat: OrderPatient): void => {
    if (!this.selectedPatient || pat?.id !== this.selectedPatient?.id) {
      this.selectedPatient = { id: pat.id, ...pat };
      if (pat.id) {
        this.appointment_date = pat.appointment_date;
        this.date_of_birth = this.datePipe.transform(pat.date_of_birth, 'mm/dd/yyyy');
        this.name = pat.first_name + ' ' + pat.last_name;
        this.patient_id = pat.patient_row_id;
        this.pat_row_id = pat.id;
      }
    }
  };

  toggleOrder(event: boolean) {
    this.showHideOrder = event;
  }

  addOrder = (record: any): void => {
    if (record.patient_row_id) {
      const selectedProvider = this.providerTableData.find(
        (provider) => provider.Name === this.selectedOption
      );
      this.selectedProviderId = selectedProvider ? selectedProvider.ID : null;

      if (!this.selectedProviderId) {
        this.toastr.warning('Please select a provider to create an order against.');
        return;
      }

      this.CreateOrderData = {
        uniq_id: record.patient_row_id,
        practice_id: this.assistantInfo.practiceId,
        provider_id: this.selectedProviderId,
      };
      this.showHideOrder = true;
    } else {
      this.toastr.warning('Select Patient First.');
    }
  };

  nextPage = (): void => {
    if (this.tablePages.nextPage) this.getAllPatients(this.tablePages.nextPage);
  };

  prevPage = (): void => {
    if (this.tablePages.prevPage) this.getAllPatients(this.tablePages.prevPage);
  };
}
