import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ASSIGN_FORM_GROUP_REQ_BODY,
  CLONE_FORM_REQ_BODY,
  CREATE_FORM_REQ_BODY,
  CREATE_RULE_REQ_BODY,
  GenericResp,
  GET_ALL_CLIENT_FORMS_RESP,
  GET_FORMS_GROUPS_RESP,
  GET_FORMS_RESPONSES_DETAILS_RESP,
  GET_FORMS_RESPONSES_RESP,
  GET_FORM_DETAILS_RESP,
  FORM_GROUP_REQ_BODY,
  GET_ASSIGNED_FORMS_RESP,
  FORM_RESPONSE_REQ_BODY,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(private http: HttpClient) {}

  async getAllClientForms(practice_id: string, client_id: string): Promise<GET_ALL_CLIENT_FORMS_RESP> {
    return await this.http
      .get<GET_ALL_CLIENT_FORMS_RESP>(`${environment.FORMS}/client/${client_id}?practice_id=${practice_id}`)
      .toPromise();
  }

  async getClientForm(form_id: string, client_id: string): Promise<GET_FORM_DETAILS_RESP> {
    return await this.http
      .get<GET_FORM_DETAILS_RESP>(`${environment.FORMS}/client/${client_id}/form/${form_id}`)
      .toPromise();
  }

  async getFormResponses(
    form_id: string,
    client_id: string,
    page: number = 1,
    limit: number = 4,
    orderBy: {},
    search: string,
    searchCriteria: string,
    filterByDate: {},
    is_archived: boolean,
  ): Promise<GET_FORMS_RESPONSES_RESP> {
    return await this.http
      .get<GET_FORMS_RESPONSES_RESP>(`${environment.FORMS}/client/${client_id}/form/${form_id}/response`, {
        params: {
          page: `${page}`,
          limit: `${limit}`,
          orderBy: JSON.stringify(orderBy),
          search,
          searchCriteria,
          filterByDate: JSON.stringify(filterByDate),
          is_archived: `${!!is_archived}`,
        },
      })
      .toPromise();
  }

  async getFormResponseDetails(
    form_id: string,
    client_id: string,
    task_id: string
  ): Promise<GET_FORMS_RESPONSES_DETAILS_RESP> {
    return await this.http
      .get<GET_FORMS_RESPONSES_DETAILS_RESP>(
        `${environment.FORMS}/client/${client_id}/form/${form_id}/task/${task_id}/response/details`
      )
      .toPromise();
  }

  async updateFormResponse(task_id: string, data: FORM_RESPONSE_REQ_BODY): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.FORMS}/task/${task_id}/response`, data)
      .toPromise();
  }

  async createClientForm(practice_id: string, client_id: string, data: CREATE_FORM_REQ_BODY): Promise<GenericResp> {
    return await this.http
      .post<GenericResp>(`${environment.FORMS}/client/${client_id}/create?practice_id=${practice_id}`, data)
      .toPromise();
  }

  async updateForm(
    practice_id: string,
    client_id: string,
    formId: string,
    data: CREATE_FORM_REQ_BODY
  ): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.FORMS}/client/${client_id}/form/${formId}/update`, data)
      .toPromise();
  }

  downloadFormResponse(form_id: string, client_id: string, task_id: string): Observable<Blob> {
    return this.http.get(`${environment.FORMS}/client/${client_id}/form/${form_id}/task/${task_id}/response/pdf`, {
      responseType: 'blob',
    });
  }

  async cloneForm(client_id: string, form_id: string, data: CLONE_FORM_REQ_BODY): Promise<GenericResp> {
    return await this.http
      .post<GenericResp>(`${environment.FORMS}/client/${client_id}/form/${form_id}/clone`, data)
      .toPromise();
  }

  async getProviders(practice_id: string): Promise<any> {
    return await this.http
      .post(environment.GET_PROVIDERS, {
        practice_id,
      })
      .toPromise();
  }

  async createRule(client_id: string, form_id: string, data: CREATE_RULE_REQ_BODY): Promise<GenericResp> {
    return await this.http
      .post<GenericResp>(`${environment.FORMS}/client/${client_id}/form/${form_id}/rule`, data)
      .toPromise();
  }

  async getFormRules(client_id: string, form_id: string): Promise<any> {
    return await this.http.get<any>(`${environment.FORMS}/client/${client_id}/form/${form_id}/rules`).toPromise();
  }

  async deleteFormRule(client_id: string, rule_id: string): Promise<GenericResp> {
    return await this.http.delete<GenericResp>(`${environment.FORMS}/client/${client_id}/rule/${rule_id}`).toPromise();
  }

  async deleteForm(client_id: string, form_id: string): Promise<GenericResp> {
    return await this.http.delete<GenericResp>(`${environment.FORMS}/client/${client_id}/form/${form_id}`).toPromise();
  }

  async createFormGroup(client_id: string, data: FORM_GROUP_REQ_BODY): Promise<GenericResp> {
    return await this.http.post<GenericResp>(`${environment.FORMS}/client/${client_id}/group`, data).toPromise();
  }

  async getAllFormGroups(client_id: string): Promise<any> {
    return await this.http.get<GET_FORMS_GROUPS_RESP>(`${environment.FORMS}/client/${client_id}/groups`).toPromise();
  }

  async deleteFormGroup(client_id: string, group_id: string): Promise<GenericResp> {
    return await this.http
      .delete<GenericResp>(`${environment.FORMS}/client/${client_id}/group/${group_id}`)
      .toPromise();
  }

  async updateFormGroup(client_id: string, group_id: string, data: FORM_GROUP_REQ_BODY): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.FORMS}/client/${client_id}/group/${group_id}`, data)
      .toPromise();
  }

  async assignFormGroup(client_id: string, group_id: string, data: ASSIGN_FORM_GROUP_REQ_BODY): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(`${environment.FORMS}/client/${client_id}/group/${group_id}/assign`, data)
      .toPromise();
  }

  async getFormsAssignedToGroup(client_id: string, group_id: string): Promise<any> {
    return await this.http
      .get<GET_ASSIGNED_FORMS_RESP>(`${environment.FORMS}/client/${client_id}/group/${group_id}/forms`)
      .toPromise();
  }
}
