<div class="page-title__bar">
  <button class="btn back-btn" (click)="navigateBackToPracticeForm()">
    Back
  </button>
  <h2 class="title">Provider Management</h2>

  <div class="user-actions">
    <button class="btn add-user-btn" (click)="addProvider()">
      Add Provider
    </button>
  </div>
</div>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="calendar"
    role="tabpanel"
    aria-labelledby="calendar-tab"
  >
    <div class="right__panel calendar-data__wrapper">
      <div class="table-data__wrapper table-responsive calendar-data--table">
        <app-table-pagination-client
          [headers]="tableHeaders"
          [data]="providerTableData"
          [hasActions]="true"
          [deleteFunction]="deleteProvider"
          [editFunction]="editProvider"
          [pageSize]="10"
          [currentPage]="1"
        >
        </app-table-pagination-client>
      </div>
    </div>
  </div>
</div>
