<div class="audio-player">
  <div>
    <p style="font-family: Arial, Helvetica, sans-serif">
      {{ msaapPlaylist?.title }}
    </p>
  </div>
  <audio
    #audioPlayer
    controls
    (timeupdate)="updateTranscription($event)"
    class="player"
  >
    <source [src]="selectedAudio?.url" />
  </audio>
  <div
    #transcriptionDiv
    *ngIf="this.transcription.length > 1"
    class="transcription"
  >
    <p style="font-family: Arial, Helvetica, sans-serif">
      <span
        (click)="onWordClick(word)"
        [class.highlighted]="word === currentWord"
        *ngFor="let word of this.transcription"
      >
        {{ word.alternatives[0].content }}
      </span>
    </p>
  </div>
  <p style="font-size: 14px; font-weight: bold">
    Edit or Update Transcription:
  </p>
  <div class="editor-text">
    <ckeditor [editor]="editor" [(ngModel)]="transcriptionText"></ckeditor>
  </div>
  <div class="button-group">
    <button
      class="btn-cancel btn-big btn-rounded btn-color"
      (click)="saveEdit()"
    >
      Save
    </button>
  </div>
</div>
<div class="page-title__bar">
  <div>
    <h2 class="title">Verahealth Dictations</h2>
  </div>
  <div>
    <div
      class="filter__panel btn-demo"
      ngbTooltip="Filter"
      (click)="openFilterModal()"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [ngClass]="selectedProvider && selectedProvider.id ? 'filtered' : ''"
        alt="filters"
      >
        <path
          d="M18.3334 2.5H1.66669L8.33335 10.3833V15.8333L11.6667 17.5V10.3833L18.3334 2.5Z"
          stroke="#001737"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div class="archived__panel mb-1 btn-demo">
      <div class="d-flex align-items-center">
        <p class="mb-1 ml-2">Non Archived &nbsp;</p>
        <ui-switch
          color="cyan"
          #attachmentSwitch
          (change)="onChangeSwitch()"
        ></ui-switch>
        <p class="mb-1 mr-2 ml-1">Archived</p>
      </div>
    </div>
    <div class="search__panel btn-demo">
      <select ngbTooltip="Search Criteria" [(ngModel)]="searchCriteria">
        <option value="patient_name">Patient Name</option>
        <option value="mrn">MRN</option>
        <option value="provider_name">Provider Name</option>
      </select>
      <div class="input-group">
        <input
          #searchBar
          type="search"
          (keyup.enter)="searchRecords($event)"
          [(ngModel)]="searchKeyword"
          (search)="searchRecords($event, true)"
          class="form-control change"
          placeholder="Search"
        />
        <div class="input-group-append" (click)="searchRecords($event)">
          <span class="input-group-text">
            <img src="../../../assets/icons/daily-screening.svg" alt="search" />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="calendar"
    role="tabpanel"
    aria-labelledby="calendar-tab"
  >
    <div class="right__panel calendar-data__wrapper">
      <div class="table-data__wrapper table-responsive calendar-data--table">
        <app-table-pagination-client
          [headers]="audioTableHeaders"
          [data]="audioDictationTableData"
          [hasActions]="true"
          [deleteFunction]="deleteAudioDictation"
          [archivedFunction]="archivedFunction"
          [downloadFunction]="downloadAudioDictation"
          [playContent]="playAudioDictation"
          [pauseContent]="pauseAudioDictation"
          [downloadTranscriptionFunction]="downloadTranscription"
          [pageSize]="pageSize"
          [currentPage]="1"
          [style]="this.style"
          [orderByFunction]="orderBy"
          [paginationFirstPage]="firstPage"
          [paginationPrevPage]="prevPage"
          [paginationNextPage]="nextPage"
          [paginationLastPage]="lastPage"
          [paginationCustomPage]="customPage"
          [serverSidePagination]="tablePages"
        >
        </app-table-pagination-client>

        <!-- <div style="margin-top: 15px">
          <h2 class="title">Acumen HealthCare Dictations</h2>
        </div>

        <app-table-pagination-client
          [headers]="acumenTableHeadersData"
          [data]="acumenDictationTableData"
          [paginateId]="2"
          [hasActions]="true"
          [deleteFunction]="acumenDeleteAudioDictation"
          [downloadFunction]="acumenDownloadAudioDictation"
          [playContent]="acumenPlayAudioDictation"
          [pauseContent]="acumenPauseAudioDictation"
          [pageSize]="6"
          [currentPage]="1"
        >
        </app-table-pagination-client> -->
        <div class="notofications_panel mb-1 btn-demo">
          <div class="d-flex align-items-center">
            <button
              class="btn action-btn"
              *ngIf="recordFound"
              (click)="deleteUnsucessfulAudios()"
            >
              Clear
            </button>
            <button
              class="btn action-btn"
              *ngIf="recordFound"
              (click)="sendMissingFilesNotifications()"
            >
              Send Notifications
            </button>
          </div>
        </div>
        <div style="margin-top: 15px">
          <h2 class="title">Missing Audio Dictations</h2>
        </div>

        <app-table-pagination-client
          [headers]="MissingAudioTableHeaders"
          [data]="MissingAudioDictationTableData"
          [pageSize]="pageSize"
          [currentPage]="1"
          [style]="this.style"
          [paginationFirstPage]="missingAudioFirstPage"
          [paginationPrevPage]="missingAudioPrevPage"
          [paginationNextPage]="missingAudioNextPage"
          [paginationLastPage]="missingAudioLastPage"
          [paginationCustomPage]="missingAudioCustomPage"
          [serverSidePagination]="missingAudioTablePages"
        >
        </app-table-pagination-client>
      </div>
    </div>
  </div>
</div>
