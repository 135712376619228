import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quick-response-modal',
  templateUrl: './quick-response-modal.component.html',
  styleUrls: ['./quick-response-modal.component.css'],
})
export class QuickResponseModalComponent implements OnInit {
  quickResponseList = [
    'Hello, this is _______ at Sierra Pacific Orthopedics. How can I help you today?',
    'Can I get a date of birth and first and last name?',
    'Hello,  How can I help you?',
    'I have your chart pulled, Hello,  How can I help you?',
    'Let me send a message to the surgery scheduler and she can give you a call back.',
    'Let me pull your chart and check the schedule',
    'Can you confirm the Doctor? And would it be ok to get you in with the PA?',
    'Can you confirm your insurance?',
    'I will need to check with the referral department.  Please give me a few moments',
    'Let me send a message to the Medical assistant and see if we can get that approved.  Give me just a few moments. If they have any questions she can give you a call back',
    'Have there been any previous surgeries in this area?',
    'Have you had a recent injury to this area?',
    'Have you had any recent imaging such as x-rays, Cat Scans, or MRI?',
    'In what regards would you like to schedule the appointment for?',
    'For Physical Therapy you would need to contact that department.  559-256-5320',
    'For the MRI department you would need to contact them at 559-256-5288',
    'You can reach the Referral Department at 559-256-5299, they would be happy to see if that referral has been received and processed yet',
    'What do you need to see the Dr. for?',
    'Let me check that schedule, is there a certain day or time of day that works better for you?',
    'Let me get that booked',
    'Thank you and have a great day.',
    'Thank you, let us know if you need further assistance',
    'Can I get a date of birth and first and last name?',
    'Hello,  How can I help you?',
    'I have your chart pulled, Hello,  How can I help you?',
    'Let me send a message to the surgery scheduler and she can give you a call back.',
    'Let me pull your chart and check the schedule.',
    'Can you confirm the Doctor? And would it be ok to get you in with the PA?',
    'Can you confirm your insurance?',
    'I will need to check with the referral department.  Please give me a few moments.',
    'Let me send a message to the Medical assistant and see if we can get that approved.  Give me just a few moments. If they have any questions she can give you a call back.',
    'Have there been any previous surgeries in this area?',
    'Have you had a recent injury to this area?  ',
    'Have you had any recent imaging such as x-rays, Cat Scans, or MRI?',
    'In what regards would you like to schedule the appointment for?',
    'For Physical Therapy you would need to contact that department.  559-256-5320',
    'For the MRI department you would need to contact them at 559-256.5288',
    'What do you need to see the Dr. for?',
    'Let me check that schedule, is there a certain day or time of day that works better for you?',
    'Let me get that booked.',
  ];
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onResponseClick(filter: string): void {
    this.activeModal.close(filter);
  }
}
