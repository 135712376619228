import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { GET_PAYERS_CLIENT, GenericResp } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PayerService {
  constructor(private http: HttpClient, private db: AngularFireDatabase) { }

  async getPayerByClientId(client_id: string): Promise<GenericResp> {
    return await this.http
      .get<GenericResp>(`${environment.GET_PAYERS_BY_CLIENT}${client_id}`)
      .toPromise();
  }
  async getPayerContracts(payer_id: string, page: number = 1,
    limit: number = 10): Promise<GenericResp> {
    return await this.http
      .get<GenericResp>(`${environment.PAYER_DATA}${payer_id}/contracts`, {
        params: { page: `${page}`, limit: `${limit}` },
      })
      .toPromise();
  }
  async getPayerMPD(payer_id: string): Promise<any> {
    return await this.http
      .get<any>(`${environment.PAYER_DATA}${payer_id}/mpd`)
      .toPromise();
  }
  uploadCSV(file, payer_id: string): Observable<any> {
    let header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('contract_rates', file);
    return this.http.post<any>(
      `${environment.PAYER_DATA}${payer_id}/contracts/upload`,
      formData
    );
  }

  async addMPD(payer_id: string, mpd, proc_order): Promise<GenericResp> {
    let data = {
      mpd: mpd,
      proc_order: proc_order,
    };
    return this.http
      .post<GenericResp>(`${environment.PAYER_DATA}/${payer_id}/mpd`, data)
      .toPromise();
  }
  async deleteMPD(payer_id: string, mpd_id: string): Promise<GenericResp> {
    return this.http
      .delete<GenericResp>(`${environment.PAYER_DATA}${payer_id}/mpd/${mpd_id}`)
      .toPromise();
  }
  async updateMPD(payer_id: string, mpd_id: string, data): Promise<any> {
    return this.http
      .put<any>(`${environment.PAYER_DATA}${payer_id}/mpd/${mpd_id}`, data)
      .toPromise();
  }
}
