import { PracticeInfoForUser, GetPracticeByIdApiResp } from './../models/practice';
import { DBConfig, DbConfigBackend } from './../models/';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { RecordingConfig } from '../models/recordingConfig';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  providers: any = null;
  practiceInfoResp: GetPracticeByIdApiResp = null;
  private navbarVisibilitySubject = new BehaviorSubject(true);
  isNavbarVisible = this.navbarVisibilitySubject.asObservable();

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  saveRecording(recordingConfig: RecordingConfig): any {
    // return this.http.post(`${environment.RECORDING_PATH}/merge`, recordingConfig);
  }

  sleep(ms: number): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async getPracticeById(practice_id: string): Promise<any> {
    try {
      if (this.practiceInfoResp) return this.practiceInfoResp.data;

      this.practiceInfoResp = await this.getPracticeByIdFromDB(practice_id);
      return this.practiceInfoResp.data;
    } catch (err) {
      this.toastr.error(err.message);
    }
  }

  async getPracticeByIdFromDB(practice_id: string): Promise<any> {
    return await this.http
      .post<GetPracticeByIdApiResp>(environment.GET_PRACTICE_BY_ID, {
        practice_id,
      })
      .toPromise();
  }

  async getProviders(practiceId: string): Promise<any> {
    if (this.providers) return this.providers;

    this.providers = await this.getProvidersFromDB(practiceId);
    return this.providers;
  }

  async getProvidersFromDB(practiceId: string): Promise<any> {
    return await this.http
      .post(environment.GET_PROVIDERS_FROM_DB, {
        dbConfig: environment.dbConfig,
        practice_id: practiceId,
      })
      .toPromise();
  }

  async getProvidersHavingDictations(client_id: string, practice_id: string, dictation_id: string): Promise<any> {
    return await this.http
    .get<any>(`${environment.GET_PROVIDER_API}/client/${client_id}/practice/${practice_id}/dictation/${dictation_id}`)
    .toPromise();
  }

  showToast(title: string, dialogType: any): void {
    Swal.fire({
      position: 'top-end',
      icon: dialogType,
      title: `${title}`,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  formatDbObject(dbConfig: DBConfig): DbConfigBackend {
    return {
      username: dbConfig.dbUsername,
      password: dbConfig.dbPassword,
      database: dbConfig.dbName,
      host: dbConfig.dbHost,
      dialect: dbConfig.dbDialect,
      port: dbConfig.dbPort,
    };
  }

  formatDbObj(
    username: string,
    password: string,
    database: string,
    host: string,
    dialect: string,
    port: number
  ): DbConfigBackend {
    return {
      username,
      password,
      database,
      host,
      dialect,
      port,
    };
  }

  getDefaultDB(): DbConfigBackend {
    return environment.dbConfig;
  }

  switchNavbarVisibility(flag: boolean): void {
    this.navbarVisibilitySubject.next(flag);
  }
}
