import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FirebaseService } from '../shared/services/firebase.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private firebaseService: FirebaseService,
    private spinnerService: NgxSpinnerService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('skip')) return next.handle(request);

    const token = localStorage.getItem('token');
    const is_blob = request.headers.get('is_blob');
    let authRequest = request;
    if (token) {
      if (is_blob) {
        authRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else
        authRequest = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
    }

    return next.handle(authRequest).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            localStorage.clear();
            this.toastr.error('Session Expired.');
            this.spinnerService.hide();
            // this.firebaseService = null;

            this.router.navigate(['/']);
          }
        }
      )
    );
  }
}
