<div class="page">
  <div class="page-title__bar">
    <h2 class="title">Activity Logs</h2>
  </div>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
      <div class="right__panel calendar-data__wrapper">
        <div class="table-data__wrapper table-responsive calendar-data--table">
          <app-table-pagination-client [headers]="tableHeaders" [data]="activityLogs" [pageSize]="10" [currentPage]="1">
          </app-table-pagination-client>
        </div>
      </div>
    </div>
  </div>
</div>
