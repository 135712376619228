<div class="modal-header">
  <h4 class="modal-title">
    Filter By: &nbsp;<strong>{{ header }}</strong>
  </h4>
  <button
    type="button"
    class="close"
    style="color: white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body container">
  <ng-container *ngIf="!selectStepStatus && !datePicker">
    <ul *ngIf="filterOptions?.filter?.length" class="groups-list">
      <li *ngFor="let record of filterOptions.filter" (click)="applyFilter(record)">
        <input
          type="radio"
          [value]="record.title"
          [checked]="selectedFilter"
          [(ngModel)]="selectedFilter"
          [id]="record.id"
          style="cursor: pointer"
        />
        <span style="cursor: pointer">{{ record.title }}</span>
      </li>
    </ul>
    <div *ngIf="!filterOptions.filter.length" class="d-flex justify-content-center mt-3 mb-3">
      <h6>No Options Available</h6>
    </div>
  </ng-container>

  <!-- DATEPICKER -->
  <ng-container *ngIf="datePicker">
    <span class="mt-4">Please select a Date Range:</span>
    <div class="pb-3 pt-2 d-flex">
      <ngb-datepicker
        #dp
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        outsideDays="hidden"
      >
      </ngb-datepicker>

      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </ng-container>

  <!-- For Order Step Status case specifically -->
  <ng-container *ngIf="selectStepStatus">
    <div class="d-flex mt-2 mb-4">
      <select class="form-control mr-4" (change)="onStepChange($event)">
        <option value="" disabled hidden selected>Select a Step</option>
        <option *ngFor="let step of filterOptions.Steps" [value]="step.title" [selected]="step.title === selectedStep">
          {{ step.title }}
        </option>
      </select>
      <select class="form-control" (change)="onStatusChange($event)">
        <option value="" disabled hidden selected>Select a Status</option>
        <option
          *ngFor="let status of filterOptions.Status"
          [value]="status.id"
          [selected]="status.id == selectedStatus"
        >
          {{ status.title }}
        </option>
      </select>
    </div>
  </ng-container>

  <div>
    <button
      class="btn remove-btn mb-3"
      *ngIf="filterOptions || datePicker || selectStepStatus"
      (click)="removeFilter()"
    >
      Remove Filter
    </button>
    <!-- For Order Step Status & Date Picker case specifically -->
    <button class="btn apply-btn mb-3" (click)="applySelectFilter()" *ngIf="datePicker">Apply Filter</button>
    <button class="btn apply-btn mb-3" (click)="applyOrderFilter()" *ngIf="selectStepStatus">Apply Filter</button>
  </div>
</div>
