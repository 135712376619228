<div class="row" style="overflow: hidden">
  <div class="col-md-5" style="height: 95vh">
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Your Chats">
        <div #talkjsContainer style="height: 90vh">
          <i>Loading chat...</i>
        </div>
      </mat-tab>
      <mat-tab *ngIf="talkjsAdmin" label="All Chats">
        <div #talkjsContainerAllChats style="height: 90vh">
          <i>Loading chat...</i>
        </div>
      </mat-tab>
      <mat-tab *ngIf="talkjsAdmin" label="Assigned Chats">
        <div *ngIf="userSelected?.length < 1" class="list-group">
          <a (click)="selectedTalkjsUser('All')" class="list-group-item list-group-item-action active"> All</a>
          <div *ngFor="let email of filteredEmails; let i = index">
            <a (click)="selectedTalkjsUser(email)" class="list-group-item list-group-item-action">{{ email }}</a>
          </div>
        </div>
        <div #talkjsContainerAssignedUser style="height: 90vh"></div>
      </mat-tab>
      <mat-tab *ngIf="talkjsAdmin" label="Archived Chats">
        <div class="modal-body">
          <div class="input-group rounded">
            <input
              type="date"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              [(ngModel)]="dateForSearchArchiveConversation"
            />
            <button
              id="search-button"
              type="button"
              class="btn btn-primary ml-2"
              (click)="searchArchiveConversations()"
            >
              <i class="fas fa-search"></i>
            </button>
            <button id="search-button" type="button" class="btn btn-primary ml-2" (click)="clearSearch()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div #talkjsContainerArchived style="height: 90vh">
          <i>Loading chat...</i>
        </div>
      </mat-tab>
      <mat-tab *ngIf="talkjsAdmin" label="Blocked Users">
        <div #talkjsContainerBlockedUsers style="height: 90vh"></div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="col-md-7">
    <div class="page-title__bar mt-3 ml-1">
      <h2
        *ngIf="
          selectedConversation?.conversation?.id.toString().length > 0 &&
          userRole !== 'practice_message_user' &&
          userRole !== 'practice_general_user'
        "
        class="title"
      >
        Chat Control Interface
      </h2>
      <div style="display: flex">
        <button *ngIf="talkjsAdmin" class="btn btn-primary report_button" (click)="downloadUserActivityReport()">
          Generate Report
        </button>
        <button *ngIf="bulkMessageAdmin" class="btn btn-primary report_button" (click)="uploadFile()">
          Bulk Messages
        </button>
        <button class="btn btn-primary report_button" (click)="sendMessage()">Send Message</button>
      </div>
    </div>
    <div *ngIf="userRole !== 'practice_message_user' && userRole !== 'practice_general_user'">
      <div>
        <p
          *ngIf="
            this.conversation?.custom?.archivedConversationCount &&
            selectedConversation?.conversation?.id.toString().length > 0
          "
          class="instruction"
          [@fade]
        >
          This conversation has been archived
          {{ this.conversation?.custom?.archivedConversationCount }}
          times.
        </p>
        <p
          *ngIf="
            !this.conversation?.custom?.archivedConversationCount &&
            selectedConversation?.conversation?.id.toString().length > 0
          "
          class="instruction"
          [@fade]
        >
          This conversation has not been archived.
        </p>
        <div
          *ngIf="
            selectedConversation?.conversation?.id.toString().length > 0 &&
            selectedUser?.id &&
            selectedUser?.id.toString().length < 15
          "
          class="container"
        >
          <div class="content" *ngIf="!addMRN">
            <h6>
              Medical Record Number: <strong>{{ this.MRN || "Not Specified" }}</strong>
            </h6>
          </div>
          <div>
            <div *ngIf="addMRN" class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Add MRN"
                aria-label="Add medical record number"
                aria-describedby="basic-addon2"
                [(ngModel)]="this.updatedMRN"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="updateMrnInConversation(selectedConversation?.conversation?.id)"
                >
                  Update
                </button>
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="toggleAddMRN('close')">close</button>
              </div>
            </div>
            <i
              *ngIf="!addMRN"
              ngbTooltip="Edit MRN"
              class="fa fa-pencil-square fa-lg pointer"
              (click)="toggleAddMRN(null)"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div
          *ngIf="selectedConversation?.conversation?.id.toString().length > 0"
          class="page-title__bar_buttons mt-2"
          [@fade]
        >
          <button
            *ngIf="!archive"
            class="btn btn-warning"
            (click)="archiveTalkjsConversation(selectedConversation.conversation.id)"
          >
            Archive
          </button>
          <button
            *ngIf="talkjsAdmin"
            class="btn btn-primary ml-2"
            (click)="downloadTalkjsConversation(selectedConversation.conversation.id)"
          >
            Download Conversation
          </button>
          <button
            *ngIf="talkjsAdmin && !blockFlage"
            class="btn btn-danger ml-2"
            (click)="blockUser(selectedConversation.conversation.id, true)"
          >
            Block User
          </button>
          <button
            *ngIf="talkjsAdmin && blockFlage"
            class="btn btn-success ml-2"
            (click)="blockUser(selectedConversation.conversation.id, false)"
          >
            Unblock User
          </button>
        </div>

        <br />
        <div *ngIf="selectedConversation?.conversation?.id.toString().length > 0" class="ml-2">
          <app-conversation-management
            [data]="selectedConversation"
            [webUsers]="this.emails"
            (reloadMessages)="handleReload($reload)"
            [admin]="talkjsAdmin"
          ></app-conversation-management>
          <hr />
        </div>
      </div>
      <br />

      <!-- Order Management -->
      <div *ngIf="selectedUser?.id.toString().length > 15" class="page-title__bar mt-3 mb-2" [@fade]>
        <h2 class="title">Order Management</h2>
      </div>
      <div *ngIf="selectedUser?.id.toString().length > 15" class="order__panel" [@fade]>
        <app-order-details-panel
          [order]="selectedOrder"
          [assistantInfo]="assistantInfo"
          [assistantUID]="assistantUID"
          [assistantPractice]="assistantPractice"
          (getPendingOrders)="getPendingOrders()"
        ></app-order-details-panel>
      </div>

      <!-- Patient Orders -->
      <div *ngIf="selectedUser?.id.toString().length > 15" class="page-title__bar" [@fade]>
        <h5 class="title">Patient Orders</h5>
      </div>
      <div *ngIf="selectedUser?.id.toString().length > 15" class="right__panel calendar-data__wrapper" [@fade]>
        <div class="table-data__wrapper table-responsive calendar-data--table">
          <app-table-pagination-client
            [headers]="orderTableHeaders"
            [data]="selectedUserOrdersTableData"
            [paginateId]="1"
            [selectContent]="selectOrder"
            [selectedContent]="selectedOrder"
            [pageSize]="2"
            [currentPage]="1"
          >
          </app-table-pagination-client>
        </div>
      </div>

      <!-- Patient Appointments -->
      <div *ngIf="selectedUser?.id.toString().length > 15" class="page-title__bar mt-4" [@fade]>
        <h5 class="title">Patient Appointments</h5>
      </div>
      <div *ngIf="selectedUser?.id.toString().length > 15" class="right__panel calendar-data__wrapper" [@fade]>
        <div class="table-data__wrapper table-responsive calendar-data--table">
          <app-table-pagination-client
            [headers]="appointmentTableHeaders"
            [data]="appointmetsTableData"
            [paginateId]="2"
            [pageSize]="5"
            [currentPage]="1"
          >
          </app-table-pagination-client>
        </div>
      </div>
    </div>
  </div>
</div>
