import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Assistant, formRulesResp, formRulesTableData, GET_FORMS_RULES_RESP } from '../../models';
import { FirebaseService } from '../../services/firebase.service';
import { FormsService } from '../../services/forms.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-form-rules-modal',
  templateUrl: './form-rules-modal.component.html',
  styleUrls: ['./form-rules-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: '0' }), animate('0.1s 300ms ease-in', style({ opacity: '1' }))]),
    ]),
  ],
})
export class FormRulesModalComponent implements OnInit {
  tableHeaders = ['no', 'rule_title', 'rule_type', 'provider', 'created_at'];
  assistantInfo: Assistant = null;
  createFlag: boolean = false;
  rule_title: string = '';
  rule_type: string = '';
  provider: string = '';
  providerData: any[];
  formRulesTableData: formRulesTableData[] = [];
  @Input() title: string;
  @Input() form_id: string;

  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private firebaseService: FirebaseService,
    private modalService: NgbModal,
    private formService: FormsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getFormRules();
  }

  getFormRules(): void {
    this.formService
      .getFormRules(this.assistantInfo.client_id, this.form_id)
      .then((resp: GET_FORMS_RULES_RESP) => {
        this.formRulesTableData = resp.data.map((rule: formRulesResp, index: number) => ({
          no: ++index,
          id: rule.id,
          rule_title: rule.rule_title,
          rule_type: rule.rule_type.charAt(0).toUpperCase() + rule.rule_type.slice(1),
          provider: rule.providers[0].description,
          created_at: DateTime.fromISO(rule.created_at).toLocaleString(DateTime.DATE_SHORT),
          order_id: rule.order_id,
        }));
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  createSwitch(): void {
    this.spinnerService.show();
    this.formService
      .getProviders(this.assistantInfo.practiceId)
      .then((resp: any) => {
        this.providerData = resp.data;
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
    this.createFlag = !this.createFlag;
  }

  cancelSwitch(): void {
    this.provider = '';
    this.rule_title = '';
    this.createFlag = !this.createFlag;
  }

  createRule(): void {
    if (!this.rule_title) {
      this.toastr.error('Please enter a rule.');
      return;
    }

    if (!this.provider) {
      this.toastr.error('Please select a provider.');
      return;
    }

    if (!this.rule_type) {
      this.toastr.error('Please select a rule type first.');
      return;
    }

    this.spinnerService.show();
    const data = {
      provider_id: this.provider,
      rule_title: this.rule_title,
      rule_type: this.rule_type,
    };

    this.formService
      .createRule(this.assistantInfo.client_id, this.form_id, data)
      .then((resp: any) => {
        this.toastr.success('New Rule Created Successfully');
        this.getFormRules();
        this.provider = '';
        this.rule_title = '';
        this.rule_type = '';
        this.createFlag = !this.createFlag;
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  deleteRule = (record: formRulesTableData): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.formService
          .deleteFormRule(this.assistantInfo.client_id, record.id)
          .then(() => {
            this.spinnerService.hide();
            this.getFormRules();
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The Form Rule was deleted Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Form Rule deletion process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };
}
